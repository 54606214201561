import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Template } from '../../../common/model/template';

export interface TemplateState {
  templates: Template[];
  templatesDataTables: DataTablesResponse;
  selectedTemplate: Template;
  reportTypes: Map<string, string>;
  groupTypes: Map<string, string>;
}

export const INIT_TEMPLATE_STATE: TemplateState = {
  templates: [],
  templatesDataTables: {
    data: [],
    draw: 0,
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  selectedTemplate: null,
  reportTypes: new Map<string, string>(),
  groupTypes: new Map<string, string>(),
};
