import { AppState } from '../../../store/app/state/app.state';
import { select, Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { filter, map } from 'rxjs/operators';
import * as UserAction from '../../../store/user/actions/user.actions';
import { User } from '../../../common/model/user.model';
import { selectUser } from '../../../store/user/selectors/user.selectors';
import { selectLanguage } from '../../../store/gui/selectors/gui.selectors';
import * as GuiActions from '../../../store/gui/actions/gui.actions';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  opened = true;
  over = 'side';
  watcher: Subscription;

  user: Observable<User>;
  language$: Observable<string>;

  constructor(media: MediaObserver, private store$: Store<AppState>) {
    this.watcher = media
      .asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0]),
      )
      .subscribe((change: MediaChange) => {
        this.opened = !(change.mqAlias === 'sm' || change.mqAlias === 'xs');
        this.over =
          change.mqAlias === 'sm' || change.mqAlias === 'xs' ? 'over' : 'side';
      });
  }

  ngOnInit() {
    this.user = this.store$.pipe(select(selectUser));
    this.language$ = this.store$.pipe(select(selectLanguage));
  }

  logout() {
    this.store$.dispatch(UserAction.logout());
  }

  ngOnDestroy(): void {
    this.watcher.unsubscribe();
  }

  toggleLanguage(language) {
    this.store$.dispatch(GuiActions.toggleLanguage({ language: language }));
  }
}
