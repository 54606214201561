import { NgModule } from '@angular/core';
import { LoginComponent } from './ui/login/login.component';
import { MenuComponent } from './ui/menu/menu.component';
import { SidenavComponent } from './ui/menu/sidenav/sidenav.component';
import { CompanyListComponent } from './ui/company/list/list.component';
import { MaterialModule } from './material.module';
import { DataTablesModule } from 'angular-datatables';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent, ProgressSpinnerOverviewExample } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AddCompanyComponent } from './ui/company/add/add.component';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmDialogComponent } from './ui/shared/confirm-dialog/confirm-dialog.component';
import { ProductListComponent } from './ui/product/list/list.component';
import { AddProductComponent } from './ui/product/add/add.component';
import { TemplateComponent } from './ui/template/add/template.component';
import { AddQuestionComponent } from './ui/question/add/add.component';
import { QuestionListComponent } from './ui/question/list/list.component';
import { TemplateQuestionListComponent } from './ui/shared/template-question-list/template-question-list.component';
import { AddReportComponent } from './ui/report/add/add.component';
import { ListReportComponent } from './ui/report/list/list.component';
import { ListTemplateComponent } from './ui/template/list/list-template.component';
import { CompanySearchComponent } from './ui/shared/company-search/company-search.component';
import { AddDropdownAnswerComponent } from './ui/dropdown-answer/add/add-dropdown-answer.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddDiscordanceComponent } from './ui/discordance/add/add.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    SidenavComponent,
    CompanyListComponent,
    AddCompanyComponent,
    ConfirmDialogComponent,
    ProductListComponent,
    AddProductComponent,
    AddQuestionComponent,
    QuestionListComponent,
    TemplateComponent,
    TemplateQuestionListComponent,
    AddReportComponent,
    ListReportComponent,
    ListTemplateComponent,
    CompanySearchComponent,
    AddDropdownAnswerComponent,
    ProgressSpinnerOverviewExample,
    AddDiscordanceComponent,
  ],
  imports: [
    MaterialModule,
    DataTablesModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      tapToDismiss: true,
      positionClass: 'toast-top-center',
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    NgxMatSelectSearchModule,

  ],
  entryComponents: [AddCompanyComponent, ConfirmDialogComponent, AddProductComponent, AddQuestionComponent, TemplateQuestionListComponent, AddDropdownAnswerComponent, ProgressSpinnerOverviewExample, AddDiscordanceComponent],
  exports: [
    LoginComponent,
    MenuComponent,
    SidenavComponent,
    CompanyListComponent,
    AddCompanyComponent,
    ConfirmDialogComponent,
    ProductListComponent,
    AddProductComponent,
    AddQuestionComponent,
    QuestionListComponent,
    TemplateComponent,
    AddReportComponent,
    CompanySearchComponent,
    AddDiscordanceComponent,
  ],
})
export class ComponentsModule {
}
