import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyApiEndpoints } from '../utils/constants';
import { Company } from '../model/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  COMPANY_URL = environment.apiUrl + CompanyApiEndpoints.BASE_COMPANY_URL;

  constructor(private http: HttpClient) {
  }

  getCompanies(type: string) {
    let params = new HttpParams();
    if (type) {
      params = params.append(CompanyApiEndpoints.TYPE_QUERY_PARAM, type);
    }
    return this.http.get(this.COMPANY_URL, { params });
  }

  addCompany(company: Company) {
    return this.http.post(this.COMPANY_URL, company);
  }

  getCompany(id: number) {
    return this.http.get<Company>(this.COMPANY_URL + id);
  }

  updateCompany(company: Company) {
    return this.http.put(this.COMPANY_URL + company.id, company);
  }

  deleteCompany(id: number) {
    return this.http.delete(this.COMPANY_URL + id);
  }

  getCompaniesDataTables(dataTablesParameters: any) {
    return this.http.post(this.COMPANY_URL + CompanyApiEndpoints.COMPANY_DATATABLE + CompanyApiEndpoints.COMPANY_SEARCH, dataTablesParameters);
  }

  searchCompanies(search: string, type: string) {
    const company = {} as Company;
    company.name = search;
    company.type = type;
    return this.http.post(this.COMPANY_URL + CompanyApiEndpoints.COMPANY_SEARCH, company);
  }
}
