import { StoreConfig } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AppState } from '../app/state/app.state';
import { ProductState } from './state/product.state';
import { ProductActions } from './actions/product.actions';

export const PRODUCT_STORAGE_KEYS = new InjectionToken<keyof ProductState[]>(
  'ProductStorageKeys',
);
export const PRODUCT_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('ProductStorage');
export const PRODUCT_CONFIG_TOKEN = new InjectionToken<StoreConfig<AppState, ProductActions>>('ProductConfigToken');
