import { UserActionNames } from '../../../common/utils/constants';
import { createAction, props, union } from '@ngrx/store';
import { User } from '../../../common/model/user.model';

export const login = createAction(
  UserActionNames.LOGIN,
  props<{ username: string; password: string }>(),
);
export const logout = createAction(UserActionNames.LOGOUT);
export const logoutSuccess = createAction(UserActionNames.LOGOUT_SUCCESS);
export const loginSuccess = createAction(
  UserActionNames.LOGIN_SUCCESS,
  props<{ user: User }>(),
);
export const loginSuccessNavigate = createAction(
  UserActionNames.LOGIN_SUCCESS_NAVIGATE,
);
export const navigateToLoginPage = createAction(
  UserActionNames.NAVIGATE_TO_LOGIN_PAGE,
);
const all = union({
  login,
  logout,
  logoutSuccess,
  loginSuccess,
  loginSuccessNavigate,
  navigateToLoginPage,
});
export type UserActions = typeof all;
