import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ANSWER_TYPE_MUNICIPALITY, CommonLabels, FieldNames, MessagesKey } from '../../../common/utils/constants';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Product } from '../../../common/model/product';
import * as ProductAction from '../../../store/product/actions/product.actions';
import * as CompanyAction from '../../../store/company/actions/company.actions';
import { selectIsModalOpen, selectProduct, selectProductTypes } from '../../../store/product/selectors/product.selectors';
import { Company, CompanyType } from '../../../common/model/company';
import { selectFilteredCompanies, selectLastAddedCompany } from '../../../store/company/selectors/company.selectors';
import { takeUntil } from 'rxjs/operators';
import { AddCompanyComponent } from '../../company/add/add.component';
import * as ReportAction from '../../../store/report/actions/report.actions';
import { DropdownAnswer } from '../../../common/model/dropdown_answer';
import { selectAnswers } from '../../../store/report/selectors/report.selectors';

@Component({
  selector: 'app-add-product',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddProductComponent implements OnInit, OnDestroy {
  form: FormGroup;
  product: Product = {} as Product;
  modalToggleSubscription: Subscription;
  productSubscription: Subscription;
  companiesSubscription: Subscription;
  companies: Company[];
  filteredCompanies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>();
  onDestroy = new Subject<void>();
  public companyFilterCtrl: FormControl = new FormControl();
  addCompanyActionCall = false;
  newCompanySubscription: Subscription;
  productTypes: Observable<Map<string, string>>;
  filteredAnswers: Observable<DropdownAnswer[]>;

  constructor(
    private dialogRef: MatDialogRef<AddProductComponent>,
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; disable: boolean; title: string },
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.filteredAnswers = this.store$.pipe(select(selectAnswers));
    this.productTypes = this.store$.pipe(select(selectProductTypes));
    this.companiesSubscription = this.store$
      .pipe(select(selectFilteredCompanies))
      .subscribe((companies) => {
        this.companies = companies;
        this.filteredCompanies.next(this.companies);
      });

    this.companyFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        this.filterCompanies(value);
      });

    this.store$.dispatch(CompanyAction.searchCompanies({ search: '', companyType: CompanyType.MANUFACTURER }));
    this.store$.dispatch(ProductAction.getProductTypes());

    this.store$.dispatch(ProductAction.toggleModal({ isOpen: true }));

    this.modalToggleSubscription = this.store$
      .pipe(select(selectIsModalOpen))
      .subscribe((data) => {
        if (!data) {
          this.dialogRef.close();
        }
      });
    if (this.data.id) {
      this.store$.dispatch(ProductAction.getProduct({ id: this.data.id }));
      this.productSubscription = this.store$
        .pipe(select(selectProduct))
        .subscribe((product) => {
          if (product) {
            this.product = {
              ...product,
              manufacturerId: product.manufacturer.id,
            };
            this.form.patchValue(this.product);
          }
        });
    }
    this.newCompanySubscription = this.store$.pipe(select(selectLastAddedCompany)).subscribe((data) => {
      if (data && this.addCompanyActionCall) {
        this.form.get(FieldNames.PRODUCT_MANUFACTURER_ID).setValue(data.id);
      }
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.PRODUCT_ID]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
      ],
      [FieldNames.PRODUCT_NAME]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_FOUNDATION_YEAR]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_TYPE]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_MANUFACTURER_ID]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_PRODUCT_ID]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_LOCATION]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_ADDITIONAL_INFO]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
      ],
      [FieldNames.PRODUCT_SERIAL_NUMBER]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.PRODUCT_MUNICIPALITY]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
    });
  }

  save() {
    this.product = this.form.value;
    this.product.manufacturer = {} as Company;
    this.product.manufacturer.id = this.form.get(
      FieldNames.PRODUCT_MANUFACTURER_ID,
    ).value;
    if (this.product.id) {
      this.store$.dispatch(
        ProductAction.updateProduct({ product: this.product }),
      );
    } else {
      this.store$.dispatch(ProductAction.addProduct({ product: this.product }));
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
    this.modalToggleSubscription.unsubscribe();
    this.companiesSubscription.unsubscribe();

    this.onDestroy.next();
    this.onDestroy.complete();
    this.newCompanySubscription.unsubscribe();
  }

  filterCompanies(search: string) {
    if (!this.companies) {
      return;
    }
    if (!search) {
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCompanies.next(
      this.companies.filter(
        (company) => company.name.toLowerCase().indexOf(search) > -1,
      ),
    );
  }

  openAddCompanyModal() {
    this.addCompanyActionCall = true;
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      disableClose: true,
      data: { id: null, disable: false, title: MessagesKey.ADD_COMPANY_TITLE, onlyManufacturer: true },
    });
  }

  onFocus() {
    this.store$.dispatch(ReportAction.getAnswers({ search: '', answerType: ANSWER_TYPE_MUNICIPALITY }));
  }

  onKeyup(value) {
    this.store$.dispatch(ReportAction.getAnswers({ search: value, answerType: ANSWER_TYPE_MUNICIPALITY }));
  }

  onFocusName() {
    this.store$.dispatch(ReportAction.getAnswers({ search: '', answerType: "PRODUCT_NAME" }));
  }

  onKeyupName(value) {
    this.store$.dispatch(ReportAction.getAnswers({ search: value, answerType: "PRODUCT_NAME" }));
  }
}
