import { Company } from '../../../common/model/company';
import { DataTablesResponse } from '../../../common/model/datatables_response';

export interface CompanyState {
  companies: Company[];
  companiesDataTables: DataTablesResponse;
  isModalOpen: boolean;
  selectedCompany: Company;
  companyTypes: Map<string, string>;
  lastAddedCompany: Company;
  filteredCompanies: Company[];
}

export const INIT_COMPANY_STATE: CompanyState = {
  companies: [],
  companiesDataTables: {
    data: [],
    draw: 0,
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  isModalOpen: false,
  selectedCompany: null,
  companyTypes: new Map<string, string>(),
  lastAddedCompany: null,
  filteredCompanies: [],
};
