import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatInput, MatSelect } from '@angular/material';
import { Observable } from 'rxjs';
import { Question } from '../../../common/model/question';
import { AppState } from '../../../store/app/state/app.state';
import { select, Store } from '@ngrx/store';
import { FieldNames, MessagesKey } from '../../../common/utils/constants';
import * as QuestionActions from '../../../store/question/actions/question.actions';
import { AddQuestionComponent } from '../add/add.component';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import {
  selectAnswerTypes,
  selectQuestionsDataTables,
} from '../../../store/question/selectors/question.selectors';

@Component({
  selector: 'app-list-question',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class QuestionListComponent implements OnInit {
  @ViewChild(MatInput, { static: false }) searchInput: MatInput;
  @ViewChild(DataTableDirective, { static: false })
  dataTableElement: DataTableDirective;
  @ViewChild(MatSelect, { static: false }) type: MatSelect;
  questions: Observable<Question[]>;
  answerTypes: Observable<Map<string, string>>;
  dtOptions: any;

  constructor(private store$: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.initDataTable();
    this.questions = this.store$.pipe(select(selectQuestionsDataTables));
    this.answerTypes = this.store$.pipe(select(selectAnswerTypes));
    this.store$.dispatch(QuestionActions.getAnswerTypes());
  }

  initDataTable() {
    this.dtOptions = {
      serverSide: true,
      processing: false,
      dom: 'lpt',
      language: {
        lengthMenu: '_MENU_ ',
        paginate: {
          previous: '&lt;',
          next: '&gt;',
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.columns[0].search[
          'value'
        ] = this.searchInput.value;
        dataTablesParameters.columns[1].search['value'] = this.type.value;
        this.store$.dispatch(
          QuestionActions.getQuestionsDataTables({
            dataTablesParameters,
            callback,
          }),
        );
      },
      columnDefs: [
        {
          searchable: false,
          targets: [0, 1, 2, 3, 4, 5],
        },
      ],
      columns: [
        { data: FieldNames.QUESTION_ID, orderable: true },
        { data: FieldNames.QUESTION_TEXT, orderable: false },
        { data: FieldNames.QUESTION_ADDITIONAL_INFO, orderable: false },
        { data: FieldNames.QUESTION_ANSWER_TYPE, orderable: false },
        { data: FieldNames.QUESTION_DEPRECATED, orderable: false },
        { data: FieldNames.QUESTION_ID, orderable: false },
      ],
    };
  }

  add() {
    this.openDialog(false, MessagesKey.ADD_QUESTION_TITLE);
  }

  search() {
    this.draw();
  }

  edit(id) {
    this.openDialog(false, MessagesKey.UPDATE_QUESTION_TITLE, id);
  }

  preview(id) {
    this.openDialog(true, MessagesKey.PREVIEW_QUESTION_TITLE, id);
  }

  delete(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        titleKey: MessagesKey.DELETE_QUESTION_TITLE,
        messagesKey: MessagesKey.DELETE_QUESTION_MESSAGES,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.store$.dispatch(QuestionActions.deleteQuestion({ id }));
      }
    });
  }

  openDialog(disable: boolean, title: string, id?) {
    const dialogRef = this.dialog.open(AddQuestionComponent, {
      disableClose: true,
      data: { id, disable, title },
      width: '50%',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.draw();
    });
  }

  draw() {
    if (!!this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }
}
