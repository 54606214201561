import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';
import { LocalStorageService } from '../../common/services/local-storage.service';
import { HttpClientModule } from '@angular/common/http';
import { companyReducers } from './reducers/company.reducers';
import { CompanyEffects } from './effects/company.effects';
import { COMPANY_CONFIG_TOKEN, COMPANY_LOCAL_STORAGE_KEY, COMPANY_STORAGE_KEYS } from './company.tokens';

export function getCompanyConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('company', companyReducers, COMPANY_CONFIG_TOKEN),
    EffectsModule.forFeature([CompanyEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    CompanyEffects,
    { provide: COMPANY_LOCAL_STORAGE_KEY, useValue: '__company_storage__' },
    { provide: COMPANY_STORAGE_KEYS, useValue: ['companies', 'companiesDataTables'] },
    {
      provide: COMPANY_CONFIG_TOKEN,
      deps: [COMPANY_STORAGE_KEYS, COMPANY_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getCompanyConfig,
    },
  ],
  entryComponents: [],
})
export class CompanyStateModule {
}
