import { StoreConfig } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { UserState } from './state/user.state';
import { UserActions } from './actions/user.actions';
import { AppState } from '../app/state/app.state';

export const USER_STORAGE_KEYS = new InjectionToken<keyof UserState[]>(
  'UserStorageKeys',
);
export const USER_LOCAL_STORAGE_KEY = new InjectionToken<string[]>(
  'UserStorage',
);
export const USER_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<AppState, UserActions>
>('UserConfigToken');
