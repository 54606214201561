import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, flatMap } from 'rxjs/operators';
import { AppState } from '../../store/app/state/app.state';
import { selectToken } from '../../store/user/selectors/user.selectors';
import * as UserAction from '../../store/user/actions/user.actions';
import { MatDialog } from '@angular/material';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private store$: Store<AppState>, private dialogRef: MatDialog) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.store$.select(selectToken).pipe(
      first(),
      flatMap(token => {
        const authRequest = !!token
          ? request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          })
          : request;

        return next.handle(authRequest).pipe(
          catchError(err => {
            if (err.status === 401) {
              this.dialogRef.closeAll();
              this.store$.dispatch(UserAction.logoutSuccess());
              this.store$.dispatch(UserAction.navigateToLoginPage());
            }
            const error = err.error.message || err.error || err.statusText;
            return throwError(error);
          }),
        );
      }),
    );
  }
}
