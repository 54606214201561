import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from '../../common/services/local-storage.service';
import { questionReducers } from './reducers/question.reducers';
import { QUESTION_CONFIG_TOKEN, QUESTION_LOCAL_STORAGE_KEY, QUESTION_STORAGE_KEYS } from './question.tokens';
import { QuestionEffects } from './effects/question.effects';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';


export function getQuestionConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('question', questionReducers, QUESTION_CONFIG_TOKEN),
    EffectsModule.forFeature([QuestionEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    QuestionEffects,
    { provide: QUESTION_LOCAL_STORAGE_KEY, useValue: '__question_storage__' },
    { provide: QUESTION_STORAGE_KEYS, useValue: ['questions', 'questionsDataTables'] },
    {
      provide: QUESTION_CONFIG_TOKEN,
      deps: [QUESTION_STORAGE_KEYS, QUESTION_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getQuestionConfig,
    },
  ],
  entryComponents: [],
})
export class QuestionStateModule {

}
