import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TypeApiEndpoints } from '../utils/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TypeService {
  TYPE_URL = environment.apiUrl + TypeApiEndpoints.BASE_TYPE_URL;


  constructor(private http: HttpClient) {
  }

  getTypeNames() {
    return this.http.get(this.TYPE_URL);
  }

  getTypeConstants() {
    return this.http.get(this.TYPE_URL + TypeApiEndpoints.TYPE_ALL);
  }

  getConstantsByType(name: string) {
    return this.http.get(this.TYPE_URL + name);
  }

  getTypesWithValue(name: string) {
    return this.http.get(this.TYPE_URL + name + TypeApiEndpoints.TYPE_VALUES);
  }

  getTypesWithGroup(name: string) {
    return this.http.get(this.TYPE_URL + name + TypeApiEndpoints.TYPE_GROUP);
  }
}
