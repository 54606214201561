export interface Company {
  id?: number;
  name: string;
  pib: string;
  address: string;
  mobilePhone: string;
  additionalInfo: string;
  type: string;
  // private String logo;
}

export enum CompanyType {
  APPLICANT = 'APPLICANT',
  MANUFACTURER = 'MANUFACTURER',
  BOTH = 'BOTH'
}
