import { GuiActions, setLoading, toggleLanguage } from '../actions/gui.actions';
import { GuiState, INIT_GUI_STATE } from '../state/gui.state';
import { createReducer, on } from '@ngrx/store';

const reducers = createReducer(
  INIT_GUI_STATE,
  on(toggleLanguage, (state, { language }) => ({
    ...state,
    language: state.availableLanguages.find((item) => item !== language),
  })),
  on(setLoading, (state, { loading }) => ({
    ...state,
    loading: loading,
  })),
);

export function guiReducers(state: GuiState | undefined, action: GuiActions) {
  return reducers(state, action);
}
