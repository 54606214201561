import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatInput, MatSelect } from '@angular/material';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subscription } from 'rxjs';
import { Report } from '../../../common/model/report';
import { AppState } from '../../../store/app/state/app.state';
import { select, Store } from '@ngrx/store';
import { FieldNames, Paths } from '../../../common/utils/constants';
import * as ReportActions from '../../../store/report/actions/report.actions';
import { selectExcelReport, selectReportsDataTables, selectExpReport } from '../../../store/report/selectors/report.selectors';
import { selectReportTypes } from '../../../store/template/selectors/template.selectors';
import * as TemplateActions from '../../../store/template/actions/template.actions';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { AddDropdownAnswerComponent } from '../../dropdown-answer/add/add-dropdown-answer.component';
import * as moment from 'moment';
import { SatDatepicker } from 'saturn-datepicker';

@Component({
  selector: 'app-list-report',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListReportComponent implements OnInit, OnDestroy {
  dtOptions: any;
  @ViewChild(MatInput, { static: false }) searchInput: MatInput;
  @ViewChild(DataTableDirective, { static: false })
  dataTableElement: DataTableDirective;
  @ViewChild(MatSelect, { static: false }) reportType: MatSelect;
  reports: Observable<Report[]>;
  reportTypes: Observable<Map<string, string>>;
  reportSubscription: Subscription;
  expSubscription: Subscription;
  excelName: string;
  @ViewChild(SatDatepicker, { static: false }) date: SatDatepicker<any>;
  @ViewChild('acceptedCheck', { static: false }) acceptedCheck: ElementRef;


  constructor(private store$: Store<AppState>, private router: Router, private dialog: MatDialog) {
    this.reports = this.store$.pipe(select(selectReportsDataTables));
    this.reportTypes = this.store$.pipe(select(selectReportTypes));
    this.store$.dispatch(TemplateActions.getReportTypes());
  }

  ngOnInit() {
    this.initDataTable();
    this.reportSubscription = this.store$
      .pipe(select(selectExcelReport))
      .subscribe((file) => {
        if (!!file) {
          saveAs(file, this.excelName + '.xlsx');
          this.store$.dispatch(ReportActions.removeExcelReport());
        }
      });
    this.expSubscription = this.store$
      .pipe(select(selectExpReport))
      .subscribe((file) => {
        if (!!file) {
          saveAs(file, this.excelName + '.xlsx');
          this.store$.dispatch(ReportActions.removeExpReport());
        }
      });
  }

  initDataTable() {
    this.dtOptions = {
      serverSide: true,
      processing: false,
      dom: 'lpt',
      language: {
        lengthMenu: '_MENU_ ',
        paginate: {
          previous: '&lt;',
          next: '&gt;',
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.columns[0].search['value'] = this.searchInput.value;
        dataTablesParameters.columns[1].search['value'] = this.reportType.value;
        if (this.date.beginDate && this.date.endDate) {
          dataTablesParameters.columns[2].search['value'] = moment(this.date.beginDate).format('yyyy-MM-DD');
          dataTablesParameters.columns[3].search['value'] = moment(this.date.endDate).format('yyyy-MM-DD');
        }
        if (this.acceptedCheck.nativeElement.checked) {
          dataTablesParameters.columns[4].search['value'] = this.acceptedCheck.nativeElement.checked;
        }
        this.store$.dispatch(
          ReportActions.getReportsDataTables({
            dataTablesParameters,
            callback,
          }),
        );
      },
      columnDefs: [
        {
          searchable: false,
          targets: [0, 1, 2, 3, 4, 5, 6, 7],
        },
        { width: '30%', targets: 2 },
      ],
      columns: [
        { data: FieldNames.REPORT_ID, orderable: false },
        { data: FieldNames.REPORT_TEMPLATE, orderable: false },
        { data: FieldNames.REPORT_TEMPLATE, orderable: false },
        { data: FieldNames.REPORT_DATE, orderable: true },
        { data: FieldNames.REPORT_PRODUCT, orderable: false },
        { data: FieldNames.REPORT_APPLICANT, orderable: false },
        { data: FieldNames.REPORT_ACCEPTED, orderable: false },
        { data: FieldNames.REPORT_ID, orderable: false },
      ],
    };
  }

  search() {
    this.draw();
  }

  draw() {
    if (!!this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  getExp() {
    const date = new Date();
    this.excelName = "report_" + date.getDate() + "." + date.getMonth() + 1 + "." + date.getFullYear();
    this.store$.dispatch(ReportActions.getExport());
  }

  generateExcel(id, info: string[]) {
    info.push(new Date().toDateString());
    console.log(info);
    this.excelName = info.join(' - ');
    this.store$.dispatch(ReportActions.getExcelReport({ id: id }));
  }

  edit(id, isCopy) {
    this.router.navigate(
      [this.router.url, Paths.EDIT, id],
      isCopy ? { queryParams: { isCopy: isCopy } } : {},
    );
  }

  ngOnDestroy(): void {
    this.reportSubscription.unsubscribe();
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddDropdownAnswerComponent, {
      disableClose: true,
      width: '50%',
    });
  }

  navigateToReportAdd() {
    this.router.navigate([Paths.REPORT_ADD]);
  }

  onChange(event) {
    this.draw();
  }
}
