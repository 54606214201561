import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { userReducers } from './reducers/user.reducers';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';
import { LocalStorageService } from '../../common/services/local-storage.service';
import {
  USER_CONFIG_TOKEN,
  USER_LOCAL_STORAGE_KEY,
  USER_STORAGE_KEYS,
} from './user.tokens';
import { UserEffects } from './effects/user.effects';
import { HttpClientModule } from '@angular/common/http';

export function getUserConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('user', userReducers, USER_CONFIG_TOKEN),
    EffectsModule.forFeature([UserEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    UserEffects,
    { provide: USER_LOCAL_STORAGE_KEY, useValue: '__user_storage__' },
    { provide: USER_STORAGE_KEYS, useValue: ['user'] },
    {
      provide: USER_CONFIG_TOKEN,
      deps: [USER_STORAGE_KEYS, USER_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getUserConfig,
    },
  ],
  entryComponents: [],
})
export class UserStateModule {}
