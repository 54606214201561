import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Discordance } from '../../../common/model/discordance';
import { Report } from '../../../common/model/report';
import { DropdownAnswer } from '../../../common/model/dropdown_answer';

export interface ReportState {
  types: Map<string, any>;
  discordanceList: Discordance[];
  reportsDataTables: DataTablesResponse;
  report: Blob;
  selectedReport: Report;
  answerList: DropdownAnswer[];
  typesWithGroup: Map<string, any>;
  isModalOpen: boolean;
  loading: boolean;
  exp: Blob;
}

export const INIT_REPORT_STATE: ReportState = {
  types: new Map<string, any>(),
  discordanceList: [],
  reportsDataTables: {
    data: [],
    draw: 0,
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  report: null,
  selectedReport: null,
  answerList: [],
  typesWithGroup: new Map<string, any>(),
  isModalOpen: false,
  loading: false,
  exp: null
};
