import { NgModule } from '@angular/core';
import { LocalStorageService } from '../../common/services/local-storage.service';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { templateReducers } from './reducers/template.reducers';
import { TEMPLATE_CONFIG_TOKEN, TEMPLATE_LOCAL_STORAGE_KEY, TEMPLATE_STORAGE_KEYS } from './template.tokens';
import { TemplateEffects } from './effects/template.effects';

export function getTemplateConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('template', templateReducers, TEMPLATE_CONFIG_TOKEN),
    EffectsModule.forFeature([TemplateEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    TemplateEffects,
    { provide: TEMPLATE_LOCAL_STORAGE_KEY, useValue: '__template_storage__' },
    { provide: TEMPLATE_STORAGE_KEYS, useValue: ['templates', 'templatesDataTables'] },
    {
      provide: TEMPLATE_CONFIG_TOKEN,
      deps: [TEMPLATE_STORAGE_KEYS, TEMPLATE_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getTemplateConfig,
    },
  ],
  entryComponents: [],
})
export class TemplateStateModule {

}
