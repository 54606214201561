import { StoreConfig } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AppState } from '../app/state/app.state';
import { CompanyState } from './state/company.state';
import { CompanyActions } from './actions/company.actions';

export const COMPANY_STORAGE_KEYS = new InjectionToken<keyof CompanyState[]>(
  'CompanyStorageKeys',
);
export const COMPANY_LOCAL_STORAGE_KEY = new InjectionToken<string[]>(
  'CompanyStorage',
);
export const COMPANY_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<AppState, CompanyActions>
>('CompanyConfigToken');
