import { Injectable } from '@angular/core';
import { TemplateService } from '../../../common/api/template.service';
import { TypeService } from '../../../common/api/type.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EnumNames, MessagesKey, Paths, TemplateActionsNames } from '../../../common/utils/constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as TemplateActions from '../../template/actions/template.actions';
import * as SharedActions from '../../shared/actions/shared.actions';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Template } from '../../../common/model/template';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TemplateEffects {
  constructor(
    private templateService: TemplateService,
    private typeService: TypeService,
    private action$: Actions,
    private router: Router,
  ) {
  }

  getTemplates$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.GET_TEMPLATES),
      switchMap((data: { withDeprecated: boolean }) =>
        this.templateService.getTemplates(data.withDeprecated).pipe(
          switchMap((templates: Template[]) =>
            of(TemplateActions.getTemplatesSuccess({ templates })),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  addTemplate$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.ADD_TEMPLATE),
      switchMap((props: { template: Template }) =>
        this.templateService.addTemplate(props.template).pipe(
          switchMap(() =>
            of(
              SharedActions.openSuccessDialog({
                messagesKey: MessagesKey.ADD_TEMPLATE_SUCCESS,
              }),
              TemplateActions.navigateToTemplateList(),
            ),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  getReportTypes$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.GET_REPORT_TYPES),
      switchMap(() =>
        this.typeService.getTypesWithValue(EnumNames.REPORT_TYPE).pipe(
          switchMap((reportTypes: Map<string, string>) =>
            of(TemplateActions.getReportTypesSuccess({ types: reportTypes })),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  getTemplate$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.GET_TEMPLATE),
      switchMap((data: { id: number }) =>
        this.templateService.getTemplate(data.id).pipe(
          switchMap((template: Template) =>
            of(TemplateActions.getTemplateSuccess({ template })),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  updateTemplate$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.UPDATE_TEMPLATE),
      switchMap((props: { template: Template }) =>
        this.templateService.updateTemplate(props.template).pipe(
          switchMap(() =>
            of(
              SharedActions.openSuccessDialog({
                messagesKey: MessagesKey.UPDATE_TEMPLATE_SUCCESS,
              }),
              TemplateActions.navigateToTemplateList(),
            ),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );
  deleteTemplate$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.DELETE_TEMPLATE),
      switchMap((props: { id: number }) =>
        this.templateService.deleteTemplate(props.id).pipe(
          switchMap(() =>
            of(
              TemplateActions.getTemplates({ withDeprecated: false }),
              SharedActions.openSuccessDialog({
                messagesKey: MessagesKey.DELETE_TEMPLATE_SUCCESS,
              }),
            ),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  getTemplatesDataTables$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.GET_TEMPLATES_DATA_TABLES),
      switchMap((data: { dataTablesParameters: any; callback: any }) =>
        this.templateService
          .getTemplatesDataTables(data.dataTablesParameters)
          .pipe(
            switchMap((response: DataTablesResponse) =>
              of(
                TemplateActions.getTemplatesDataTablesSuccess({
                  dataTableResponse: response,
                  callback: data.callback,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getTemplatesDataTablesSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(TemplateActionsNames.GET_TEMPLATES_DATA_TABLES_SUCCESS),
        map(
          (data: { dataTableResponse: DataTablesResponse; callback: any }) => {
            data.callback({
              recordsTotal: data.dataTableResponse.recordsTotal,
              recordsFiltered: data.dataTableResponse.recordsFiltered,
              data: [],
            });
          },
        ),
      ),
    { dispatch: false },
  );

  getGroupTypes$ = createEffect(() =>
    this.action$.pipe(
      ofType(TemplateActionsNames.GET_GROUP_TYPES),
      switchMap(() =>
        this.typeService.getTypesWithValue(EnumNames.GROUP_TYPE).pipe(
          switchMap((groupTypes: Map<string, string>) =>
            of(TemplateActions.getGroupTypesSuccess({ types: groupTypes })),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );
  navigateToTemplateList$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(TemplateActionsNames.NAVIGATE_TO_TEMPLATE_LIST),
        map(() => {
          this.router.navigate([Paths.TEMPLATE]);
        }),
      ),
    { dispatch: false },
  );
}
