import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemplateApiEndpoints } from '../utils/constants';
import { environment } from '../../../environments/environment';
import { Template } from '../model/template';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {

  TEMPLATE_URL = environment.apiUrl + TemplateApiEndpoints.BASE_TEMPLATE_URL;

  constructor(private http: HttpClient) {
  }

  addTemplate(template: Template) {
    return this.http.post(this.TEMPLATE_URL, template);
  }

  updateTemplate(template: Template) {
    return this.http.put(this.TEMPLATE_URL + template.id, template);
  }

  getTemplate(id: number) {
    return this.http.get<Template>(this.TEMPLATE_URL + id);
  }

  deleteTemplate(id: number) {
    return this.http.delete(this.TEMPLATE_URL + id);
  }

  getTemplatesDataTables(dataTablesParametars: any) {
    return this.http.post(this.TEMPLATE_URL + TemplateApiEndpoints.TEMPLATE_SEARCH, dataTablesParametars);
  }

  getTemplates(withDepricated) {
    let params = new HttpParams();
    params = params.append(TemplateApiEndpoints.DEPRECATED_QUERY_PARAM, withDepricated);
    return this.http.get(this.TEMPLATE_URL, { params });
  }
}
