import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { QuestionApiEndpoints } from '../utils/constants';
import { HttpClient } from '@angular/common/http';
import { Question } from '../model/question';

@Injectable(
  { providedIn: 'root' },
)
export class QuestionService {
  QUESTION_URL = environment.apiUrl + QuestionApiEndpoints.BASE_QUESTION_URL;

  constructor(private http: HttpClient) {
  }

  addQuestion(question: Question) {
    return this.http.post(this.QUESTION_URL, question);
  }

  getQuestion(id: number) {
    return this.http.get<Question>(this.QUESTION_URL + id);
  }

  updateQuestion(question: Question) {
    return this.http.put(this.QUESTION_URL, question);
  }

  deleteQuestion(id: number) {
    return this.http.delete(this.QUESTION_URL + id);
  }

  getQuestionsDataTables(dataTablesParametars: any) {
    return this.http.post(this.QUESTION_URL + QuestionApiEndpoints.QUESTION_SEARCH, dataTablesParametars);
  }

  getQuestions() {
    return this.http.get(this.QUESTION_URL);
  }

}
