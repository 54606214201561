import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import * as CompanyActions from '../../../store/company/actions/company.actions';
import { Observable } from 'rxjs';
import { selectCompaniesDataTables } from '../../../store/company/selectors/company.selectors';
import { FieldNames, MessagesKey } from '../../../common/utils/constants';
import { MatDialog, MatInput } from '@angular/material';
import { AddCompanyComponent } from '../add/add.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { Company } from '../../../common/model/company';

@Component({
  selector: 'app-list-company',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class CompanyListComponent implements OnInit {
  dtOptions: any = {};
  @ViewChild(DataTableDirective, { static: false })
  dataTableElement: DataTableDirective;
  @ViewChild(MatInput, { static: false }) search: MatInput;
  companies: Observable<Company[]>;

  constructor(private store$: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.companies = this.store$.pipe(select(selectCompaniesDataTables));
    // this.store$.dispatch(CompanyActions.getCompanies());
    this.initDataTable();
  }

  initDataTable() {
    this.dtOptions = {
      language: {
        lengthMenu: '_MENU_ ',
        paginate: {
          previous: '&lt;',
          next: '&gt;',
        },
      },
      serverSide: true,
      processing: false,
      dom: 'lpt',
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.search.value = this.search.value;
        this.store$.dispatch(
          CompanyActions.getCompaniesDataTables({
            dataTablesParameters,
            callback,
          }),
        );
      },
      columns: [
        { data: FieldNames.COMPANY_ID, orderable: true },
        { data: FieldNames.COMPANY_ID, orderable: false },
        { data: FieldNames.COMPANY_NAME, orderable: false },
        { data: FieldNames.COMPANY_PIB, orderable: false },
        { data: FieldNames.COMPANY_ADDRESS, orderable: false },
        { data: FieldNames.COMPANY_MOBILE_PHONE, orderable: false },
      ],
      columnDefs: [{ searchable: false, targets: [0, 1, 5] }],
    };
  }

  addCompany() {
    this.openDialog(false, MessagesKey.ADD_COMPANY_TITLE);
  }

  previewCompany(id) {
    this.openDialog(true, MessagesKey.PREVIEW_COMPANY_TITLE, id);
  }

  editCompany(id) {
    this.openDialog(false, MessagesKey.UPDATE_COMPANY_TITLE, id);
  }

  deleteCompany(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        titleKey: MessagesKey.DELETE_COMPANY_TITLE,
        messagesKey: MessagesKey.DELETE_COMPANY_MESSAGES,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.store$.dispatch(CompanyActions.deleteCompany({ id: id }));
      }
    });
  }

  openDialog(disable: boolean, title: string, id?) {
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      disableClose: true,
      data: { id, disable, title },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.draw();
    });
  }

  draw() {
    if (!!this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  searchDataTable() {
    this.draw();
  }
}
