export interface GuiState {
  availableLanguages: string[];
  language: string;
  loading: boolean;
}

export const INIT_GUI_STATE: GuiState = {
  availableLanguages: ['en', 'sr'],
  language: 'en',
  loading: false,
};
