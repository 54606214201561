import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from './store/app/state/app.state';
import { select, State } from '@ngrx/store';
import { selectLanguage, selectLoading } from './store/gui/selectors/gui.selectors';
import { DateAdapter, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'akredito-web';
  language: Observable<string>;
  dialogRefSpinner: MatDialogRef<ProgressSpinnerOverviewExample, any>;
  loadingSubscription: Subscription;

  constructor(private translate: TranslateService,
              private store$: State<AppState>,
              private dateAdapter: DateAdapter<any>,
              private dialog: MatDialog,
  ) {
    translate.setDefaultLang('sr');
  }

  ngOnInit(): void {
    this.store$.pipe(select(selectLanguage)).subscribe((language) => {
      this.translate.use(language);
      this.dateAdapter.setLocale(language);
    });
    this.loadingSubscription = this.store$.pipe(select(selectLoading)).subscribe((loading) => {
      loading ? this.openSpinner() : this.closeSpinner();
    });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }

  private openSpinner() {
    if (this.dialogRefSpinner) {
      this.dialogRefSpinner.close();
    }
    this.dialogRefSpinner = this.dialog.open(ProgressSpinnerOverviewExample, { disableClose: true, panelClass: 'nt-spinner' });
  }

  private closeSpinner() {
    if (this.dialogRefSpinner) {
      this.dialogRefSpinner.close();
    }
  }
}

@Component({
  selector: 'progress-spinner-overview-example',
  template: '<div class="nt-spinner"><mat-spinner></mat-spinner></div>',
})
export class ProgressSpinnerOverviewExample {
}
