import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ERROR, SharedActionNames, SUCCESS } from '../../../common/utils/constants';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SharedEffects {
  constructor(private action$: Actions, private notificationMessages: ToastrService, private translateService: TranslateService) {
  }

  openSuccessDialog = createEffect(
    () => this.action$.pipe(
      ofType(SharedActionNames.OPEN_SUCCESS_DIALOG),
      map((data: { messagesKey: string }) => {
        this.notificationMessages.success(this.translateService.instant(data.messagesKey), this.translateService.instant(SUCCESS));
      }),
    ), { dispatch: false },
  );

  openErrorDialog = createEffect(
    () => this.action$.pipe(
      ofType(SharedActionNames.OPEN_ERROR_DIALOG),
      map((data: { messagesKey: string[] }) => {
        data.messagesKey.forEach((message) => {
          this.notificationMessages.error(this.translateService.instant(message), this.translateService.instant(ERROR));
        });
      }),
    ), { dispatch: false },
  );
}
