import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app/state/app.state';
import { selectToken } from '../../store/user/selectors/user.selectors';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as UserAction from '../../store/user/actions/user.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private store$: Store<AppState>) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const tokenRequired = route.data.tokenRequired;
    return this.store$.pipe(
      select(selectToken),
      map(token => {
        if (!token) {
          tokenRequired ? this.store$.dispatch(UserAction.navigateToLoginPage()) : null;
          return !tokenRequired;
        }
        !tokenRequired ? this.store$.dispatch(UserAction.loginSuccessNavigate()) : null;
        return tokenRequired;
      }),
      take(1),
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
