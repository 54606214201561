import { CommonLabels, FieldNames } from '../../common/utils/constants';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app/state/app.state';
import * as UserAction from '../../store/user/actions/user.actions';
import * as GuiActions from '../../store/gui/actions/gui.actions';
import { Observable } from 'rxjs';
import { selectLanguage } from '../../store/gui/selectors/gui.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  language$: Observable<string>;

  constructor(
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.language$ = this.store$.pipe(select(selectLanguage));
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.USER_NAME]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.PASSWORD]: [CommonLabels.EMPTY, Validators.required],
    });
  }

  login() {
    const username = this.form.get(FieldNames.USER_NAME).value;
    const password = this.form.get(FieldNames.PASSWORD).value;
    this.store$.dispatch(UserAction.login({ username, password }));
  }

  toggleLanguage(language) {
    this.store$.dispatch(GuiActions.toggleLanguage({ language }));
  }
}
