import { LocalStorageService } from '../../common/services/local-storage.service';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reportReducers } from '../report/reducers/report.reduccers';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { ReportEffects } from './effects/report.effects';
import { REPORT_CONFIG_TOKEN, REPORT_LOCAL_STORAGE_KEY, REPORT_STORAGE_KEYS } from './report.tokens';

export function getReportConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('report', reportReducers, REPORT_CONFIG_TOKEN),
    EffectsModule.forFeature([ReportEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    ReportEffects,
    { provide: REPORT_LOCAL_STORAGE_KEY, useValue: '__report_storage__' },
    { provide: REPORT_STORAGE_KEYS, useValue: ['types'] },
    {
      provide: REPORT_CONFIG_TOKEN,
      deps: [REPORT_STORAGE_KEYS, REPORT_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getReportConfig,
    },
  ],
  entryComponents: [],
})
export class ReportStateModule {

}
