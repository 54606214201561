import { createAction, props, union } from '@ngrx/store';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { ProductActionNames } from '../../../common/utils/constants';
import { Product } from '../../../common/model/product';

export const getProducts = createAction(ProductActionNames.GET_PRODUCTS);
export const getProductsSuccess = createAction(ProductActionNames.GET_PRODUCTS_SUCCESS, props<{ products: Product[] }>());
export const getProductsDataTables = createAction(ProductActionNames.GET_PRODUCTS_DATA_TABLES, props<{ dataTablesParameters: any, callback: any }>());
export const getProductsDataTablesSuccess = createAction(ProductActionNames.GET_PRODUCTS_DATA_TABLES_SUCCESS, props<{ dataTableResponse: DataTablesResponse, callback: any }>());
export const addProduct = createAction(ProductActionNames.ADD_PRODUCT, props<{ product: Product }>());
export const addProductSuccess = createAction(ProductActionNames.ADD_PRODUCT_SUCCESS, props<{ product: Product }>());
export const toggleModal = createAction(ProductActionNames.TOGGLE_MODAL, props<{ isOpen: boolean }>());
export const getProduct = createAction(ProductActionNames.GET_PRODUCT, props<{ id: number }>());
export const getProductSuccess = createAction(ProductActionNames.GET_PRODUCT_SUCCESS, props<{ product: Product }>());
export const updateProduct = createAction(ProductActionNames.UPDATE_PRODUCT, props<{ product: Product }>());
export const deleteProduct = createAction(ProductActionNames.DELETE_PRODUCT, props<{ id: number }>());
export const getProductTypes = createAction(ProductActionNames.GET_PRODUCT_TYPES);
export const getProductTypesSuccess = createAction(ProductActionNames.GET_PRODUCT_TYPES_SUCCESS, props<{ types: Map<string, string> }>());

const all = union({
  getProducts,
  getProductsSuccess,
  getProductsDataTables,
  getProductsDataTablesSuccess,
  addProduct,
  addProductSuccess,
  toggleModal,
  getProduct,
  getProductSuccess,
  updateProduct,
  deleteProduct,
  getProductTypes,
  getProductTypesSuccess,
});
export type ProductActions = typeof all;
