import { GuiActionNames } from '../../../common/utils/constants';
import { createAction, props, union } from '@ngrx/store';

export const toggleLanguage = createAction(
  GuiActionNames.TOGGLE_LANGUAGE,
  props<{ language: string }>(),
);

export const setLoading = createAction(
  GuiActionNames.SET_LOADING,
  props<{ loading: boolean }>(),
);
const all = union({
  toggleLanguage,
  setLoading,
});
export type GuiActions = typeof all;
