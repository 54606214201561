import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Company, CompanyType } from '../../../common/model/company';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import * as CompanyAction from '../../../store/company/actions/company.actions';
import { selectFilteredCompanies } from '../../../store/company/selectors/company.selectors';
import { CompanyService } from '../../../common/api/company.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySearchComponent),
      multi: true,
    },
  ],
})
export class CompanySearchComponent
  implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
  /** control for the selected company */
  @Input() companyCtrl: FormControl = new FormControl();
  @Input() companyType: string;
  /** control for the MatSelect filter keyword */
  public companyFilterCtrl: FormControl = new FormControl();

  public filteredCompanies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>();

  protected onDestroy = new Subject<void>();
  companiesSubscription: Subscription;


  onChange: any = (_: any) => {
  };
  onTouched: any = (_: any) => {
  };

  constructor(private store$: Store<AppState>, private companyService: CompanyService) {
  }


  ngOnInit(): void {
    this.companiesSubscription = this.store$
      .pipe(select(selectFilteredCompanies))
      .subscribe((response) => {
        console.log(this.companyFilterCtrl.value);
        if (this.companyFilterCtrl.value !== null && this.companyFilterCtrl.value !== '') {
          this.filteredCompanies.next(response.slice());
        }
      });

    this.companyFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        this.store$.dispatch(CompanyAction.searchCompanies({ search: value, companyType: CompanyType.BOTH }));
      });


    this.companyCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => this.onChange(value));
  }

  ngOnDestroy(): void {
    this.companiesSubscription.unsubscribe();

    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(value: string) {
    this.companyCtrl.setValue(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onBlur() {
    this.store$.dispatch(CompanyAction.searchCompaniesSuccess({ companies: [] }));
  }

  ngAfterViewInit(): void {
    if (this.companyCtrl.value !== null && this.companyCtrl.value !== '') {
      this.companyService.getCompany(this.companyCtrl.value).subscribe(company => {
        this.filteredCompanies.next([company]);
      });
    }
  }


}
