import { Paths } from './../../../common/utils/constants';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypeService } from '../../../common/api/type.service';
import { ReportService } from '../../../common/api/report.service';
import { EnumNames, MessagesKey, ReportActionNames } from '../../../common/utils/constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SharedActions from '../../shared/actions/shared.actions';
import { Report } from '../../../common/model/report';
import * as ReportActions from '../../../store/report/actions/report.actions';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Discordance } from '../../../common/model/discordance';
import { DropdownAnswer } from '../../../common/model/dropdown_answer';
import { Store } from '@ngrx/store';
import { AppState } from '../../app/state/app.state';
import * as GuiAction from '../../../store/gui/actions/gui.actions';

@Injectable()
export class ReportEffects {
  constructor(private router: Router, private action$: Actions,
    private reportService: ReportService,
    private typeService: TypeService,
    private store$: Store<AppState>) {
  }


  addReport$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.ADD_REPORT),
      switchMap((props: { report: Report }) => this.reportService.addReport(props.report).pipe(
        switchMap(() => of(SharedActions.openSuccessDialog({ messagesKey: MessagesKey.ADD_REPORT_SUCCESS }), ReportActions.navigateToReportList())),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
  getTypes$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_TYPES),
      switchMap(() => this.typeService.getTypeConstants().pipe(
        switchMap((types: Map<string, any>) => of(ReportActions.getTypesSuccess({ types }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );


  getDiscordance$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_DISCORDANCE),
      switchMap((props: { search: string }) => this.reportService.searchDiscordance(props.search).pipe(
        switchMap((result: Discordance[]) => of(ReportActions.getDiscordanceSuccess({ discordance: result }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  getReportsDataTables$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_REPORTS_DATA_TABLES),
      switchMap((data: { dataTablesParameters: any, callback: any }) => this.reportService.getReportsDataTables(data.dataTablesParameters).pipe(
        switchMap((response: DataTablesResponse) => of(ReportActions.getReportsDataTablesSuccess(
          { dataTableResponse: response, callback: data.callback },
        )),
        )),
      ),
    ));

  getReportsDataTablesSuccess$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_REPORTS_DATA_TABLES_SUCCESS),
      map((data: { dataTableResponse: DataTablesResponse, callback: any }) => {
        data.callback({
          recordsTotal: data.dataTableResponse.recordsTotal,
          recordsFiltered: data.dataTableResponse.recordsFiltered,
          data: [],
        });
      }),
    ), { dispatch: false },
  );

  getExcelReport$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_EXCEL_REPORT),
      switchMap((data: { id: number }) => this.reportService.getExcelReport(data.id).pipe(
        switchMap((data: Blob) => of(ReportActions.getExcelReportSuccess({ file: data }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  getExp$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_EXP),
      switchMap(() => this.reportService.getExp().pipe(
        switchMap((data: Blob) => of(ReportActions.getExportSuccess({ file: data }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  getReport$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_REPORT),
      switchMap((data: { id: number }) => {
        this.store$.dispatch(GuiAction.setLoading({ loading: true }));
        return this.reportService.getReport(data.id).pipe(
          switchMap((data: Report) => of(ReportActions.getReportSuccess({ report: data }), GuiAction.setLoading({ loading: false }))),
          catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }), GuiAction.setLoading({ loading: false }))),
        );
      })),
  );
  updateReport$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.UPDATE_REPORT),
      switchMap((data: { report: Report }) => this.reportService.updateReport(data.report).pipe(
        switchMap(() => of(SharedActions.openSuccessDialog({ messagesKey: MessagesKey.UPDATE_REPORT_SUCCESS }), ReportActions.navigateToReportList())),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      ))),
  );

  getAnswer$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_ANSWER),
      switchMap((props: { search: string, answerType: string }) => this.reportService.searchAnswer(props.search, props.answerType).pipe(
        switchMap((result: DropdownAnswer[]) => of(ReportActions.getAnswersSuccess({ answers: result }), ReportActions.setLoading({ loading: false }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }), ReportActions.setLoading({ loading: false }))),
      )),
    ),
  );

  getTypesWithGroup$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.GET_TYPES_WITH_GROUP),
      switchMap(() => this.typeService.getTypesWithGroup(EnumNames.ANSWER_TYPE).pipe(
        switchMap((types: Map<string, any>) => of(ReportActions.getTypesWithGroupSuccess({ types }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  addDropdownAnswer$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.ADD_DROPDOWN_ANSWER),
      switchMap((props: { answer: DropdownAnswer }) => this.reportService.addDropdownAnswer(props.answer).pipe(
        switchMap(() => of(ReportActions.toggleModal({ isOpen: false }), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.ADD_ANSWER_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  addDiscordance$ = createEffect(
    () => this.action$.pipe(
      ofType(ReportActionNames.ADD_DISCORDANCE),
      switchMap((props: { discordance: Discordance }) => this.reportService.addDiscordance(props.discordance).pipe(
        switchMap((data: Discordance) => of(ReportActions.toggleModal({ isOpen: false }), ReportActions.addDiscordanceSuccess({ discordance: data }), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.ADD_DISCORDANCE_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  navigateToReportList$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ReportActionNames.NAVIGATE_TO_REPORT_LIST),
        map(() => {
          this.router.navigate([Paths.REPORT]);
        }),
      ),
    { dispatch: false },
  );


}
