import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';
import { LocalStorageService } from '../../common/services/local-storage.service';
import { HttpClientModule } from '@angular/common/http';
import { productReducers } from './reducers/product.reducers';
import { ProductEffects } from './effects/product.effects';
import { PRODUCT_CONFIG_TOKEN, PRODUCT_LOCAL_STORAGE_KEY, PRODUCT_STORAGE_KEYS } from './product.tokens';

export function getProductConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('product', productReducers, PRODUCT_CONFIG_TOKEN),
    EffectsModule.forFeature([ProductEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    ProductEffects,
    { provide: PRODUCT_LOCAL_STORAGE_KEY, useValue: '__product_storage__' },
    { provide: PRODUCT_STORAGE_KEYS, useValue: ['products', 'selectedProduct'] },
    {
      provide: PRODUCT_CONFIG_TOKEN,
      deps: [PRODUCT_STORAGE_KEYS, PRODUCT_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getProductConfig,
    },
  ],
  entryComponents: [],
})
export class ProductStateModule {
}
