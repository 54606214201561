import { StoreConfig } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AppState } from '../app/state/app.state';
import { GuiActions } from './actions/gui.actions';
import { GuiState } from './state/gui.state';

export const GUI_STORAGE_KEYS = new InjectionToken<keyof GuiState[]>(
  'GuiStorageKeys',
);
export const GUI_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('GuiStorage');
export const GUI_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<AppState, GuiActions>
>('GuiConfigToken');
