import { createAction, props, union } from '@ngrx/store';
import { ReportActionNames } from '../../../common/utils/constants';
import { Report } from '../../../common/model/report';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Discordance } from '../../../common/model/discordance';
import { DropdownAnswer } from '../../../common/model/dropdown_answer';

export const addReport = createAction(
  ReportActionNames.ADD_REPORT,
  props<{ report: Report }>(),
);
export const getTypes = createAction(ReportActionNames.GET_TYPES);
export const getTypesSuccess = createAction(
  ReportActionNames.GET_TYPES_SUCCESS,
  props<{ types: Map<string, any> }>(),
);
export const getDiscordance = createAction(
  ReportActionNames.GET_DISCORDANCE,
  props<{ search: string }>(),
);
export const getDiscordanceSuccess = createAction(
  ReportActionNames.GET_DISCORDANCE_SUCCESS,
  props<{ discordance: Discordance[] }>(),
);

export const addDiscordance = createAction(
  ReportActionNames.ADD_DISCORDANCE,
  props<{ discordance: Discordance }>(),
);
export const addDiscordanceSuccess = createAction(
  ReportActionNames.ADD_DISCORDANCE_SUCCESS,
  props<{ discordance: Discordance }>(),
);

export const getReportsDataTables = createAction(
  ReportActionNames.GET_REPORTS_DATA_TABLES,
  props<{ dataTablesParameters: any; callback: any }>(),
);
export const getReportsDataTablesSuccess = createAction(
  ReportActionNames.GET_REPORTS_DATA_TABLES_SUCCESS,
  props<{ dataTableResponse: DataTablesResponse; callback: any }>(),
);
export const getExcelReport = createAction(
  ReportActionNames.GET_EXCEL_REPORT,
  props<{ id: number }>(),
);
export const getExcelReportSuccess = createAction(
  ReportActionNames.GET_EXCEL_REPORT_SUCCESS,
  props<{ file: Blob }>(),
);
export const removeExcelReport = createAction(
  ReportActionNames.REMOVE_EXCEL_REPORT,
);
export const removeExpReport = createAction(
  ReportActionNames.REMOVE_EXP_REPORT,
);
export const getReport = createAction(
  ReportActionNames.GET_REPORT,
  props<{ id: number }>(),
);
export const getReportSuccess = createAction(
  ReportActionNames.GET_REPORT_SUCCESS,
  props<{ report: Report }>(),
);
export const updateReport = createAction(
  ReportActionNames.UPDATE_REPORT,
  props<{ report: Report }>(),
);
export const removeReport = createAction(ReportActionNames.REMOVE_REPORT);

export const getAnswers = createAction(
  ReportActionNames.GET_ANSWER,
  props<{ search: string; answerType: string }>(),
);
export const getAnswersSuccess = createAction(
  ReportActionNames.GET_ANSWER_SUCCESS,
  props<{ answers: DropdownAnswer[] }>(),
);

export const getTypesWithGroup = createAction(ReportActionNames.GET_TYPES_WITH_GROUP);
export const getTypesWithGroupSuccess = createAction(
  ReportActionNames.GET_TYPES_WITH_GROUP_SUCCESS,
  props<{ types: Map<string, any> }>(),
);
export const addDropdownAnswer = createAction(
  ReportActionNames.ADD_DROPDOWN_ANSWER,
  props<{ answer: DropdownAnswer }>(),
);
export const setLoading = createAction(
  ReportActionNames.SET_LOADING,
  props<{ loading: boolean }>(),
);
export const navigateToReportList = createAction(
  ReportActionNames.NAVIGATE_TO_REPORT_LIST,
);
export const toggleModal = createAction(ReportActionNames.TOGGLE_MODAL, props<{ isOpen: boolean }>());

export const getExport = createAction(
  ReportActionNames.GET_EXP
);
export const getExportSuccess = createAction(
  ReportActionNames.GET_EXP_SUCCESS,
  props<{ file: Blob }>(),
);

const all = union({
  addReport,
  getTypes,
  getTypesSuccess,
  getDiscordance,
  getDiscordanceSuccess,
  getReportsDataTables,
  getReportsDataTablesSuccess,
  getExcelReport,
  getExcelReportSuccess,
  removeExcelReport,
  removeExpReport,
  getReport,
  getReportSuccess,
  updateReport,
  removeReport,
  getTypesWithGroup,
  getTypesWithGroupSuccess,
  addDropdownAnswer,
  toggleModal,
  setLoading,
  addDiscordance,
  addDiscordanceSuccess,
});

export type ReportActions = typeof all;
