import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyActionNames, EnumNames, MessagesKey } from '../../../common/utils/constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CompanyService } from '../../../common/api/company.service';
import { Company } from '../../../common/model/company';
import { of } from 'rxjs';
import * as CompanyActions from '../actions/company.actions';
import * as SharedActions from '../../shared/actions/shared.actions';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { TypeService } from '../../../common/api/type.service';

@Injectable()
export class CompanyEffects {
  constructor(
    private action$: Actions,
    private companyService: CompanyService,
    private typeService: TypeService,
  ) {
  }

  getCompanies$ = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyActionNames.GET_COMPANIES),
      switchMap((props: { companyType: string }) =>
        this.companyService.getCompanies(props.companyType).pipe(
          switchMap((companies: Company[]) =>
            of(CompanyActions.getCompaniesSuccess({ companies })),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  addCompany$ = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyActionNames.ADD_COMPANY),
      switchMap((props: { company: Company }) =>
        this.companyService.addCompany(props.company).pipe(
          switchMap((data: Company) =>
            of(
              CompanyActions.toggleModal({ isOpen: false }),
              CompanyActions.addCompanySuccess({ company: data }),
              SharedActions.openSuccessDialog({
                messagesKey: MessagesKey.ADD_COMPANY_SUCCESS,
              }),
            ),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );
  getCompany$ = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyActionNames.GET_COMPANY),
      switchMap((data: { id: number }) =>
        this.companyService.getCompany(data.id).pipe(
          switchMap((company: Company) =>
            of(CompanyActions.getCompanySuccess({ company: company })),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );

  updateCompany$ = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyActionNames.UPDATE_COMPANY),
      switchMap((props: { company: Company }) =>
        this.companyService.updateCompany(props.company).pipe(
          switchMap(() =>
            of(
              CompanyActions.toggleModal({ isOpen: false }),
              SharedActions.openSuccessDialog({
                messagesKey: MessagesKey.UPDATE_COMPANY_SUCCESS,
              }),
            ),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );
  deleteCompany$ = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyActionNames.DELETE_COMPANY),
      switchMap((props: { id: number }) =>
        this.companyService.deleteCompany(props.id).pipe(
          switchMap(() =>
            of(
              CompanyActions.getCompanies(null),
              SharedActions.openSuccessDialog({
                messagesKey: MessagesKey.DELETE_COMPANY_SUCCESS,
              }),
            ),
          ),
          catchError((error) =>
            of(SharedActions.openErrorDialog({ messagesKey: error })),
          ),
        ),
      ),
    ),
  );
  getCompaniesDataTables$ = createEffect(() =>
    this.action$.pipe(
      ofType(CompanyActionNames.GET_COMPANIES_DATA_TABLES),
      switchMap((data: { dataTablesParameters: any; callback: any }) =>
        this.companyService
          .getCompaniesDataTables(data.dataTablesParameters)
          .pipe(
            switchMap((response: DataTablesResponse) =>
              of(
                CompanyActions.getCompaniesDataTablesSuccess({
                  dataTableResponse: response,
                  callback: data.callback,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getCompaniesDataTablesSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(CompanyActionNames.GET_COMPANIES_DATA_TABLES_SUCCESS),
        map(
          (data: { dataTableResponse: DataTablesResponse; callback: any }) => {
            data.callback({
              recordsTotal: data.dataTableResponse.recordsTotal,
              recordsFiltered: data.dataTableResponse.recordsFiltered,
              data: [],
            });
          },
        ),
      ),
    { dispatch: false },
  );

  getCompanyTypes$ = createEffect(
    () => this.action$.pipe(
      ofType(CompanyActionNames.GET_COMPANY_TYPES),
      switchMap(() => this.typeService.getTypesWithValue(EnumNames.COMPANY_TYPE).pipe(
        switchMap((companyTypes: Map<string, string>) => of(CompanyActions.getCompanyTypesSuccess({ types: companyTypes }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  searchCompanies$ = createEffect(
    () => this.action$.pipe(
      ofType(CompanyActionNames.SEARCH_COMPANIES),
      switchMap((props: { search: string, companyType: string }) => this.companyService.searchCompanies(props.search, props.companyType).pipe(
        switchMap((result: Company[]) => of(CompanyActions.searchCompaniesSuccess({ companies: result }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
}
