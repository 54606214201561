import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import { CommonLabels, FieldNames } from '../../../common/utils/constants';
import * as QuestionActions from '../../../store/question/actions/question.actions';
import { Observable, Subscription } from 'rxjs';
import {
  selectAnswerTypes,
  selectIsModalOpen,
  selectQuestion,
} from '../../../store/question/selectors/question.selectors';
import { Question } from '../../../common/model/question';

@Component({
  selector: 'app-add-question',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddQuestionComponent implements OnInit, OnDestroy {
  form: FormGroup;
  modalToggleSubscription: Subscription;
  questionSubscription: Subscription;
  question: Question = {} as Question;
  answerTypes: Observable<Map<string, string>>;

  constructor(
    private dialogRef: MatDialogRef<AddQuestionComponent>,
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; disable: boolean; title: string },
  ) {}

  ngOnInit() {
    this.initForm();
    this.answerTypes = this.store$.pipe(select(selectAnswerTypes));
    this.store$.dispatch(QuestionActions.toggleModal({ isOpen: true }));
    this.store$.dispatch(QuestionActions.getAnswerTypes());

    this.modalToggleSubscription = this.store$
      .pipe(select(selectIsModalOpen))
      .subscribe((data) => {
        if (!data) {
          this.dialogRef.close();
        }
      });

    if (this.data.id) {
      this.store$.dispatch(QuestionActions.getQuestion({ id: this.data.id }));
      this.questionSubscription = this.store$
        .pipe(select(selectQuestion))
        .subscribe((question) => {
          if (question) {
            this.question = { ...question };
            this.form.patchValue(this.question);
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.questionSubscription) {
      this.questionSubscription.unsubscribe();
    }
    if (this.modalToggleSubscription) {
      this.modalToggleSubscription.unsubscribe();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.QUESTION_ID]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
      ],
      [FieldNames.QUESTION_TEXT]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.QUESTION_ADDITIONAL_INFO]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
      ],
      [FieldNames.QUESTION_ANSWER_TYPE]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.question = this.form.value;
    if (this.question.id) {
      this.store$.dispatch(
        QuestionActions.updateQuestion({ question: this.question }),
      );
    } else {
      this.store$.dispatch(
        QuestionActions.addQuestion({ question: this.question }),
      );
    }
  }
}
