import { Paths } from './common/utils/constants';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './ui/login/login.component';
import { MenuComponent } from './ui/menu/menu.component';
import { AuthGuardService } from './common/auth/auth-guard.service';
import { CompanyListComponent } from './ui/company/list/list.component';
import { ProductListComponent } from './ui/product/list/list.component';
import { QuestionListComponent } from './ui/question/list/list.component';
import { TemplateComponent } from './ui/template/add/template.component';
import { ListReportComponent } from './ui/report/list/list.component';
import { AddReportComponent } from './ui/report/add/add.component';
import { ListTemplateComponent } from './ui/template/list/list-template.component';

const routes: Routes = [
  {
    path: Paths.LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuardService],
    data: { tokenRequired: false },
  },
  {
    path: '',
    component: MenuComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: { tokenRequired: true },
    children: [
      { path: Paths.ROOT, redirectTo: Paths.REPORT, pathMatch: 'full' },
      { path: Paths.COMPANY, component: CompanyListComponent, data: { tokenRequired: true } },
      { path: Paths.PRODUCT, component: ProductListComponent, data: { tokenRequired: true } },
      { path: Paths.QUESTION, component: QuestionListComponent, data: { tokenRequired: true } },
      { path: Paths.TEMPLATE, component: ListTemplateComponent, data: { tokenRequired: true } },
      { path: Paths.TEMPLATE_ADD, component: TemplateComponent, data: { tokenRequired: true } },
      { path: Paths.TEMPLATE_EDIT, component: TemplateComponent, data: { tokenRequired: true } },
      { path: Paths.REPORT, component: ListReportComponent, data: { tokenRequired: true } },
      { path: Paths.REPORT_ADD, component: AddReportComponent, data: { tokenRequired: true } },
      { path: Paths.REPORT_EDIT, component: AddReportComponent, data: { tokenRequired: true } },
    ],
  },
  { path: '**', redirectTo: Paths.REPORT },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
