import { createAction, props, union } from '@ngrx/store';
import { SharedActionNames } from '../../../common/utils/constants';

export const openSuccessDialog = createAction(SharedActionNames.OPEN_SUCCESS_DIALOG, props<{ messagesKey: string }>());
export const openErrorDialog = createAction(SharedActionNames.OPEN_ERROR_DIALOG, props<{ messagesKey: string[] }>());

const all = union({
  openSuccessDialog,
  openErrorDialog,
});
export type SharedActions = typeof all;
