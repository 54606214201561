import { createReducer, on } from '@ngrx/store';
import {
  addCompanySuccess,
  CompanyActions,
  getCompaniesDataTablesSuccess,
  getCompaniesSuccess,
  getCompanySuccess,
  getCompanyTypesSuccess,
  searchCompaniesSuccess,
  toggleModal,
} from '../actions/company.actions';
import { CompanyState, INIT_COMPANY_STATE } from '../state/company.state';
import { Company } from '../../../common/model/company';

const reducers = createReducer(
  INIT_COMPANY_STATE,
  on(getCompaniesSuccess, (state, { companies }) => ({
    ...state,
    companies: companies,
    companiesDataTables: {
      ...state.companiesDataTables,
      data: companies,
    },
  })),
  on(toggleModal, (state, { isOpen }) => ({
    ...state,
    isModalOpen: isOpen,
  })),
  on(getCompanySuccess, (state, { company }) => ({
    ...state,
    selectedCompany: company,
  })),
  on(getCompaniesDataTablesSuccess, (state, { dataTableResponse }) => ({
    ...state,
    companiesDataTables: dataTableResponse,
  })),
  on(getCompanyTypesSuccess, (state, { types }) => ({
    ...state,
    companyTypes: types,
  })),
  on(searchCompaniesSuccess, (state, { companies }) => ({
    ...state,
    filteredCompanies: companies,
  })),
  on(searchCompaniesSuccess, (state, { companies }) => ({
    ...state,
    filteredCompanies: companies,
  })),
  on(addCompanySuccess, (state, { company }) => ({
    ...state,
    lastAddedCompany: company,
    companies: getNewCompanyList(state.companies, company),
    filteredCompanies: getNewCompanyList(state.filteredCompanies, company)
  })),
);

function getNewCompanyList(companies: Company[], company: Company) {
  const copyCompanies = companies.slice();
  copyCompanies.push(company);
  return copyCompanies;
}

export function companyReducers(
  state: CompanyState | undefined,
  action: CompanyActions,
) {
  return reducers(state, action);
}
