import { createAction, props, union } from '@ngrx/store';
import { CompanyActionNames } from '../../../common/utils/constants';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Company } from '../../../common/model/company';

export const getCompanies = createAction(
  CompanyActionNames.GET_COMPANIES,
  props<{ companyType: string }>(),
);
export const getCompaniesSuccess = createAction(
  CompanyActionNames.GET_COMPANIES_SUCCESS,
  props<{ companies: Company[] }>(),
);
export const getCompaniesDataTables = createAction(
  CompanyActionNames.GET_COMPANIES_DATA_TABLES,
  props<{ dataTablesParameters: any; callback: any }>(),
);
export const getCompaniesDataTablesSuccess = createAction(
  CompanyActionNames.GET_COMPANIES_DATA_TABLES_SUCCESS,
  props<{ dataTableResponse: DataTablesResponse; callback: any }>(),
);
export const addCompany = createAction(
  CompanyActionNames.ADD_COMPANY,
  props<{ company: Company }>(),
);
export const addCompanySuccess = createAction(
  CompanyActionNames.ADD_COMPANY_SUCCESS,
  props<{ company: Company }>(),
);
export const toggleModal = createAction(
  CompanyActionNames.TOGGLE_MODAL,
  props<{ isOpen: boolean }>(),
);
export const getCompany = createAction(
  CompanyActionNames.GET_COMPANY,
  props<{ id: number }>(),
);
export const getCompanySuccess = createAction(
  CompanyActionNames.GET_COMPANY_SUCCESS,
  props<{ company: Company }>(),
);
export const updateCompany = createAction(
  CompanyActionNames.UPDATE_COMPANY,
  props<{ company: Company }>(),
);
export const deleteCompany = createAction(
  CompanyActionNames.DELETE_COMPANY,
  props<{ id: number }>(),
);
export const getCompanyTypes = createAction(
  CompanyActionNames.GET_COMPANY_TYPES,
);

export const getCompanyTypesSuccess = createAction(
  CompanyActionNames.GET_COMPANY_TYPES_SUCCESS,
  props<{ types: Map<string, string> }>(),
);

export const searchCompanies = createAction(
  CompanyActionNames.SEARCH_COMPANIES,
  props<{ search: string; companyType: string }>(),
);

export const searchCompaniesSuccess = createAction(
  CompanyActionNames.SEARCH_COMPANIES_SUCCESS,
  props<{ companies: Company[] }>(),
);

const all = union({
  getCompanies,
  getCompaniesSuccess,
  getCompaniesDataTables,
  getCompaniesDataTablesSuccess,
  addCompany,
  addCompanySuccess,
  toggleModal,
  getCompany,
  getCompanySuccess,
  updateCompany,
  deleteCompany,
  getCompanyTypes,
  getCompanyTypesSuccess,
  searchCompanies,
  searchCompaniesSuccess,
});
export type CompanyActions = typeof all;
