import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Template } from '../../../common/model/template';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import { selectReportTypes, selectTemplatesDataTables } from '../../../store/template/selectors/template.selectors';
import * as TemplateActions from '../../../store/template/actions/template.actions';
import { FieldNames, Paths } from '../../../common/utils/constants';
import { MatInput, MatSelect } from '@angular/material';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-list',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss'],
})
export class ListTemplateComponent implements OnInit {
  dtOptions: any;
  @ViewChild(MatInput, { static: false }) searchInput: MatInput;
  @ViewChild(DataTableDirective, { static: false }) dataTableElement: DataTableDirective;
  @ViewChild(MatSelect, { static: false }) reportType: MatSelect;
  templates: Observable<Template[]>;
  reportTypes: Observable<Map<string, string>>;

  constructor(private store$: Store<AppState>, private router: Router) {
    this.templates = this.store$.pipe(select(selectTemplatesDataTables));
    this.reportTypes = this.store$.pipe(select(selectReportTypes));
    this.store$.dispatch(TemplateActions.getReportTypes());
  }

  ngOnInit() {
    this.initDataTable();
  }

  initDataTable() {
    this.dtOptions = {
      serverSide: true,
      processing: false,
      dom: 'lpt',
      language: {
        lengthMenu: '_MENU_ ',
        paginate: {
          previous: '&lt;',
          next: '&gt;',
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.columns[0].search['value'] = this.searchInput.value;
        dataTablesParameters.columns[1].search['value'] = this.reportType.value;
        this.store$.dispatch(TemplateActions.getTemplatesDataTables({ dataTablesParameters, callback }));
      },
      columnDefs: [
        {
          searchable: false,
          targets: [0, 1, 2, 3, 4],
        },
        { width: '30%', targets: 2 },
      ],
      columns: [
        { data: FieldNames.TEMPLATE_ID, orderable: false },
        { data: FieldNames.TEMPLATE_NAME, orderable: false },
        { data: FieldNames.TEMPLATE_REPORT_TYPE, orderable: false },
        { data: FieldNames.TEMPLATE_CREATE_AT, orderable: false },
        { data: FieldNames.TEMPLATE_ID, orderable: false },
      ],
    };
  }

  search() {
    this.draw();
  }

  draw() {
    if (!!this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  edit(id: number) {
    this.router.navigate([this.router.url, Paths.EDIT, id]);
  }
}
