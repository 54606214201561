import { Product } from '../../../common/model/product';
import { DataTablesResponse } from '../../../common/model/datatables_response';

export interface ProductState {
  products: Product[];
  isModalOpen: boolean;
  selectedProduct: Product;
  productsDataTables: DataTablesResponse;
  productTypes: Map<string, string>;
  lastAddedProduct: Product
}

export const INIT_PRODUCT_STATE: ProductState = {
  products: [],
  isModalOpen: false,
  selectedProduct: null,
  productsDataTables: {
    data: [],
    draw: 0,
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  productTypes: new Map<string, string>(),
  lastAddedProduct: null,
};
