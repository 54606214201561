import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import { appReducers } from './store/app/reducers/app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ROOT_LOCAL_STORAGE_KEY, ROOT_STORAGE_KEYS } from './app.tokens';
import { LocalStorageService } from './common/services/local-storage.service';
import { storageMetaReducerFactory } from './common/services/storage.metareducer';
import { UserStateModule } from './store/user/user.state.module';
import { AuthenticationInterceptor } from './common/interceptors/authentication.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GuiStateModule } from './store/gui/gui.state.module';
import { ComponentsModule } from './components.module';
import { CompanyStateModule } from './store/company/company.state.module';
import { SharedEffects } from './store/shared/effects/shared.effects';
import { ProductStateModule } from './store/product/product.state.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuestionStateModule } from './store/question/question.state.module';
import { TemplateStateModule } from './store/template/template.state.module';
import { ReportStateModule } from './store/report/report.state.module';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MomentUtcDateAdapter } from './common/config/adapter';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    UserStateModule,
    GuiStateModule,
    CompanyStateModule,
    ProductStateModule,
    QuestionStateModule,
    TemplateStateModule,
    ReportStateModule,
    NgxMatSelectSearchModule,
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([SharedEffects]),
    ComponentsModule,
  ],
  providers: [
    { provide: ROOT_STORAGE_KEYS, useValue: [''] },
    { provide: ROOT_LOCAL_STORAGE_KEY, useValue: '__app_storage__' },

    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: META_REDUCERS,
      deps: [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: storageMetaReducerFactory,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
