import { logoutSuccess, UserActions } from './../actions/user.actions';
import { UserState } from './../state/user.state';
import { createReducer, on } from '@ngrx/store';
import { INIT_USER_STATE } from '../state/user.state';
import { loginSuccess } from '../actions/user.actions';
import { User } from '../../../common/model/user.model';

const reducers = createReducer(
  INIT_USER_STATE,
  on(loginSuccess, (state, { user }) => ({
    ...state,
    user: user,
  })),
  on(logoutSuccess, state => ({
    ...state,
    user: <User>{},
  })),
);

export function userReducers(
  state: UserState | undefined,
  action: UserActions,
) {
  return reducers(state, action);
}
