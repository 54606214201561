import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReportApiEndpoints } from '../utils/constants';
import { Discordance } from '../model/discordance';
import { Report } from '../model/report';
import { DropdownAnswer } from '../model/dropdown_answer';

@Injectable(
  { providedIn: 'root' },
)
export class ReportService {

  REPORT_URL = environment.apiUrl + ReportApiEndpoints.BASE_REPORT_URL;

  constructor(private http: HttpClient) {
  }

  getExp() {
    return this.http.get(this.REPORT_URL + '/expiring/', { responseType: 'blob' as 'json' });
  }

  addReport(report) {
    return this.http.post(this.REPORT_URL, report);
  }

  searchDiscordance(search) {
    const discordance = {} as Discordance;
    discordance.text = search;
    return this.http.post(this.REPORT_URL + ReportApiEndpoints.REPORT_DISCORDANCE + '/' + ReportApiEndpoints.REPORT_SEARCH, discordance);
  }

  addDiscordance(discordance: Discordance) {
    return this.http.post(this.REPORT_URL + ReportApiEndpoints.REPORT_DISCORDANCE, discordance);
  }

  getReportsDataTables(dataTablesParametars: any) {
    return this.http.post(this.REPORT_URL + ReportApiEndpoints.REPORT_SEARCH, dataTablesParametars);
  }

  getExcelReport(id) {
    return this.http.get(this.REPORT_URL + ReportApiEndpoints.REPORT_GENERATE + id, { responseType: 'blob' as 'json' });

  }

  getReport(id: number) {
    return this.http.get(this.REPORT_URL + id);
  }

  updateReport(report: Report) {
    return this.http.put(this.REPORT_URL + report.id, report);
  }

  searchAnswer(search: string, type: string) {
    const answer = {} as DropdownAnswer;
    answer.value = search;
    answer.answerType = type;
    return this.http.post(this.REPORT_URL + ReportApiEndpoints.ANSWER + ReportApiEndpoints.REPORT_SEARCH, answer);
  }

  addDropdownAnswer(answer) {
    return this.http.post(this.REPORT_URL + ReportApiEndpoints.ANSWER, answer);
  }

}
