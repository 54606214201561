export class Paths {
  static LOGIN = 'login';
  static COMPANY = 'companies';
  static ROOT = '';
  static HOME = 'home';
  static PRODUCT = 'products';
  static QUESTION = 'questions';
  static TEMPLATE = 'template';
  static REPORT = 'report';
  static ADD = 'add';
  static EDIT = 'edit';
  static REPORT_ADD = Paths.REPORT + '/' + Paths.ADD;
  static TEMPLATE_ADD = Paths.TEMPLATE + '/' + Paths.ADD;
  static TEMPLATE_EDIT = Paths.TEMPLATE + '/' + Paths.EDIT + '/:id';
  static REPORT_EDIT = Paths.REPORT + '/' + Paths.EDIT + '/:id';
}

export class FieldNames {
  static USER_NAME = 'userName';
  static PASSWORD = 'password';

  static COMPANY_ID = 'id';
  static COMPANY_LOGO = 'id';
  static COMPANY_NAME = 'name';
  static COMPANY_PIB = 'pib';
  static COMPANY_ADDRESS = 'address';
  static COMPANY_MOBILE_PHONE = 'mobilePhone';
  static COMPANY_ADDITIONAL_INFO = 'additionalInfo';
  static COMPANY_TYPE = 'type';


  static PRODUCT_ID = 'id';
  static PRODUCT_NAME = 'name';
  static PRODUCT_FOUNDATION_YEAR = 'foundationYear';
  static PRODUCT_TYPE = 'type';
  static PRODUCT_MANUFACTURER = 'manufacturer';
  static PRODUCT_MANUFACTURER_ID = 'manufacturerId';
  static PRODUCT_LOCATION = 'location';
  static PRODUCT_ADDITIONAL_INFO = 'additionalInfo';
  static PRODUCT_SERIAL_NUMBER = 'serialNumber';
  static PRODUCT_PRODUCT_ID = 'productID';
  static PRODUCT_MUNICIPALITY = 'municipality';

  static QUESTION_ID = 'id';
  static QUESTION_TEXT = 'text';
  static QUESTION_ANSWER_TYPE = 'answerType';
  static QUESTION_DEPRECATED = 'deprecated';
  static QUESTION_ADDITIONAL_INFO = 'additionalInfo';

  static TEMPLATE_ID = 'id';
  static TEMPLATE_CREATE_AT = 'createdAt';
  static TEMPLATE_NAME = 'name';
  static TEMPLATE_REPORT_TYPE = 'reportType';
  static TEMPLATE_GROUPS = 'groups';
  static TEMPLATE_GROUP_NAME = 'name';
  static TEMPLATE_GROUP_DESCRIPTION = 'description';
  static TEMPLATE_GROUP_TYPE = 'groupType';
  static TEMPLATE_GROUP_QUESTIONS = 'questions';
  static TEMPLATE_GROUP_SUBGROUPS = 'subgroups';
  static TEMPLATE_GROUP_HEADER_ITEMS = 'headerItems';
  static TEMPLATE_GROUP_HEADER_ITEM_VALUE = 'value';
  static TEMPLATE_GROUP_HEADER_ITEM_ANSWER_TYPE = 'answerType';
  static TEMPLATE_GROUP_HEADER_ITEM_ANSWER = 'answer';
  static TEMPLATE_GROUP_ID = 'id';
  static TEMPLATE_GROUP_HEADER_ITEM_ID = 'id';

  static REPORT_ID = 'id';
  static REPORT_DATE = 'datetime';
  static REPORT_PRODUCT = 'product';
  static REPORT_APPLICANT = 'applicant';
  static REPORT_INVESTOR = 'investor';
  static REPORT_TEMPLATE = 'template';
  static REPORT_ACCEPTED = 'accepted';
  static REPORT_NUMBER = 'reportNumber';
  static REPORT_WORK_ORDER_NUMBER = 'workOrderNumber';
  static REPORT_CONTROL_DATE = 'controlDate';
  static REPORT_ISSUE_DATE = 'issueDate';
  static REPORT_EXPIRY_DATE = 'expiryDate';
  static REPORT_EXPIRY_TYPE = 'expiryType';

  static REPORT_DISCORDANCES = 'discordances';

  static DISCORDANCE_ID = 'discordanceId';
  static DISCORDANCE_CORRECTIVE_MEASURE = 'correctiveMeasure';
  static DISCORDANCE_SEPARATE = 'separate';
  static DISCORDANCE_TEXT = 'text';
  static DISCORDANCE_BIG_PROBLEM = 'bigProblem';

  static DROPDOWN_ANSWER_VALUE = 'value';

}

export class AuthApiEndpoints {
  static LOGOUT = 'logout';
  static LOGIN = 'login';
  static BASE_AUTH_URL = 'auth/';
}

export class CompanyApiEndpoints {
  static BASE_COMPANY_URL = 'companies/';
  static COMPANY_SEARCH = 'search';
  static COMPANY_DATATABLE = 'datatable/';
  static TYPE_QUERY_PARAM = 'type';
}

export class ProductApiEndpoints {
  static BASE_PRODUCT_URL = 'products/';
  static PRODUCT_SEARCH = 'search';
}

export class QuestionApiEndpoints {
  static BASE_QUESTION_URL = 'questions/';
  static QUESTION_SEARCH = 'search';
}

export class TypeApiEndpoints {
  static BASE_TYPE_URL = 'types/';
  static TYPE_ALL = 'full';
  static TYPE_VALUES = '/values';
  static TYPE_GROUP = '/group';
}

export class TemplateApiEndpoints {
  static BASE_TEMPLATE_URL = 'templates/';
  static TEMPLATE_SEARCH = 'search';
  static DEPRECATED_QUERY_PARAM = 'withDeprecated';
}

export class ReportApiEndpoints {
  static BASE_REPORT_URL = 'reports/';
  static REPORT_DISCORDANCE = 'discordances';
  static REPORT_SEARCH = 'search';
  static REPORT_GENERATE = 'generate/';
  static ANSWER = 'answers/';
}

export enum CompanyActionNames {
  GET_COMPANIES = '[Company] Get Companies',
  GET_COMPANIES_SUCCESS = '[Company] Get Companies Success',
  GET_COMPANIES_DATA_TABLES = '[Company] Get Companies DataTables',
  GET_COMPANIES_DATA_TABLES_SUCCESS = '[Company] Get Companies DataTables Success',
  ADD_COMPANY = '[Company] Add Company',
  ADD_COMPANY_SUCCESS = '[Company] Add Company Success',
  TOGGLE_MODAL = '[Company] Toggle Modal isOpen',
  GET_COMPANY = '[Company] Get Company',
  GET_COMPANY_SUCCESS = '[Company] Get Company Success',
  UPDATE_COMPANY = '[Company] Update Company',
  DELETE_COMPANY = '[Company] Delete Company',
  GET_COMPANY_TYPES = '[Company] Get company types',
  GET_COMPANY_TYPES_SUCCESS = '[Company] Get company types success',
  SEARCH_COMPANIES = '[Company] Search Companies',
  SEARCH_COMPANIES_SUCCESS = '[Company] Search Companies Success',
}

export enum ProductActionNames {
  GET_PRODUCTS = '[Product] Get Products',
  GET_PRODUCTS_SUCCESS = '[Product] Get Products Success',
  GET_PRODUCTS_DATA_TABLES = '[Product] Get Products DataTables',
  GET_PRODUCTS_DATA_TABLES_SUCCESS = '[Product] Get Products DataTables Success',
  ADD_PRODUCT = '[Product] Add Product',
  ADD_PRODUCT_SUCCESS = '[Product] Add Product Success',
  TOGGLE_MODAL = '[Product] Toggle Modal isOpen',
  GET_PRODUCT = '[Product] Get Product',
  GET_PRODUCT_SUCCESS = '[Product] Get Product Success',
  UPDATE_PRODUCT = '[Product] Update Product',
  DELETE_PRODUCT = '[Product] Delete Product',
  GET_PRODUCT_TYPES = '[Product] Get Product Types',
  GET_PRODUCT_TYPES_SUCCESS = '[Product] Get Product Types Success',
}

export enum QuestionActionsNames {
  GET_QUESTIONS = '[Question] Get Questions',
  GET_QUESTIONS_SUCCESS = '[Question] Get Questions Success',
  GET_QUESTIONS_DATA_TABLES = '[Question] Get Questions DataTables',
  GET_QUESTIONS_DATA_TABLES_SUCCESS = '[Question] Get Questions DataTables Success',
  ADD_QUESTION = '[Question] Add Question',
  ADD_QUESTION_SUCCESS = '[Question] Add Question Success',
  TOGGLE_MODAL = '[Question] Toggle Modal isOpen',
  GET_QUESTION = '[Question] Get Question',
  GET_QUESTION_SUCCESS = '[Question] Get Question Success',
  UPDATE_QUESTION = '[Question] Update Question',
  DELETE_QUESTION = '[Question] Delete Question',
  GET_ANSWER_TYPES = '[Question] Get answer types',
  GET_ANSWER_TYPES_SUCCESS = '[Question] Get answer types success',
}

export enum TemplateActionsNames {
  GET_TEMPLATES = '[Template] Get Templates',
  GET_TEMPLATES_SUCCESS = '[Template] Get Templates Success',
  GET_TEMPLATES_DATA_TABLES = '[Template] Get Templates DataTables',
  GET_TEMPLATES_DATA_TABLES_SUCCESS = '[Question] Get Templates DataTables Success',
  ADD_TEMPLATE = '[Template] Add Template',
  TOGGLE_MODAL = '[Template] Toggle Modal isOpen',
  GET_TEMPLATE = '[Template] Get Template',
  GET_TEMPLATE_SUCCESS = '[Template] Get Template Success',
  UPDATE_TEMPLATE = '[Template] Update Template',
  DELETE_TEMPLATE = '[Template] Delete Template',
  GET_REPORT_TYPES = '[Template] Get report types',
  GET_REPORT_TYPES_SUCCESS = '[Template] Get report types success',
  GET_GROUP_TYPES = '[Template] Get group types',
  GET_GROUP_TYPES_SUCCESS = '[Template] Get group types success',
  REMOVE_TEMPLATE = '[Template] Remove  template',
  NAVIGATE_TO_TEMPLATE_LIST = '[Template] Navigate To Template List',
}

export enum ReportActionNames {
  ADD_REPORT = '[Report] Add Report',
  ADD_REPORT_SUCCESS = '[Report] Add Report Success',
  GET_TYPES = '[Report] Get types',
  GET_TYPES_SUCCESS = '[Report] Get types success',
  GET_DISCORDANCE = '[Report] Get discordance',
  GET_DISCORDANCE_SUCCESS = '[Report] Get discordance success',
  GET_REPORTS_DATA_TABLES = '[Report] Get Reports DataTables',
  GET_REPORTS_DATA_TABLES_SUCCESS = '[Report] Get Reports DataTables Success',
  GET_EXCEL_REPORT = '[Report] Get Excel Report',
  GET_EXCEL_REPORT_SUCCESS = '[Report] Get Excel Report Success',
  REMOVE_EXCEL_REPORT = '[Report] Remove Excel Report',
  REMOVE_EXP_REPORT = '[Report] Remove Excel Report',
  GET_REPORT = '[Report] Get Report',
  GET_REPORT_SUCCESS = '[Report] Get Report Success',
  UPDATE_REPORT = '[Report] Update Report',
  REMOVE_REPORT = '[Report] Remove Report',
  GET_ANSWER = '[Report] Get answer',
  GET_ANSWER_SUCCESS = '[Report] Get answer success',
  GET_TYPES_WITH_GROUP = '[Report] Get types with group',
  GET_TYPES_WITH_GROUP_SUCCESS = '[Report] Get types with group success',
  ADD_DROPDOWN_ANSWER = '[Report] Add Dropdown Answer',
  TOGGLE_MODAL = '[Report] Toggle Modal isOpen',
  SET_LOADING = '[Report] Set Loading',
  NAVIGATE_TO_REPORT_LIST = '[REPORT] Navigate To Template List',
  ADD_DISCORDANCE = '[Report] Add discordance',
  ADD_DISCORDANCE_SUCCESS = '[Report] Add discordance success',
  GET_EXP = '[Report] Get exp',
  GET_EXP_SUCCESS = '[Report] Get exp success'
}

export enum SharedActionNames {
  OPEN_SUCCESS_DIALOG = '[Shared] Open Success Dialog',
  OPEN_ERROR_DIALOG = '[Shared] Open Error Dialog',
}

export enum EnumNames {
  PRODUCT_TYPE = 'ProductType',
  ANSWER_TYPE = 'AnswerType',
  COMPANY_TYPE = 'CompanyType',
  REPORT_TYPE = 'ReportType',
  QUESTION_TYPE = 'QuestionType',
  GROUP_TYPE = 'GroupType',
}

export class GuiActionNames {
  static TOGGLE_LANGUAGE = '[Gui] Toggle Language';
  static SET_LOADING = '[Gui] Set Loading';
}

export class UserActionNames {
  static LOGIN = '[User] Login Action';
  static LOGOUT = '[User] Logout Action';
  static LOGOUT_SUCCESS = '[User] Logout Action Success';
  static LOGIN_SUCCESS = '[User] Login Action Success';
  static LOGIN_SUCCESS_NAVIGATE = '[User] Login Success Navigate';
  static NAVIGATE_TO_LOGIN_PAGE = '[User] Navigate To Login Page';
}

export class CommonLabels {
  static EMPTY = '';
}

export class MessagesKey {
  static ADD_COMPANY_SUCCESS = 'company.notification.add.success';
  static UPDATE_COMPANY_SUCCESS = 'company.notification.update.success';
  static DELETE_COMPANY_TITLE = 'company.notification.delete.title';
  static DELETE_COMPANY_MESSAGES = 'company.notification.delete.messages';
  static DELETE_COMPANY_SUCCESS = 'company.notification.delete.success';
  static ADD_COMPANY_TITLE = 'company.title.add';
  static UPDATE_COMPANY_TITLE = 'company.title.update';
  static PREVIEW_COMPANY_TITLE = 'company.title.preview';

  static ADD_PRODUCT_SUCCESS = 'product.notification.add.success';
  static UPDATE_PRODUCT_SUCCESS = 'product.notification.update.success';
  static DELETE_PRODUCT_TITLE = 'product.notification.delete.title';
  static DELETE_PRODUCT_MESSAGES = 'product.notification.delete.messages';
  static DELETE_PRODUCT_SUCCESS = 'product.notification.delete.success';
  static ADD_PRODUCT_TITLE = 'product.title.add';
  static UPDATE_PRODUCT_TITLE = 'product.title.update';
  static PREVIEW_PRODUCT_TITLE = 'product.title.preview';

  static ADD_QUESTION_SUCCESS = 'question.notification.add.success';
  static UPDATE_QUESTION_SUCCESS = 'question.notification.update.success';
  static DELETE_QUESTION_TITLE = 'question.notification.delete.title';
  static DELETE_QUESTION_MESSAGES = 'question.notification.delete.messages';
  static DELETE_QUESTION_SUCCESS = 'question.notification.delete.success';
  static ADD_QUESTION_TITLE = 'question.title.add';
  static UPDATE_QUESTION_TITLE = 'question.title.update';
  static PREVIEW_QUESTION_TITLE = 'question.title.preview';

  static ADD_TEMPLATE_SUCCESS = 'template.notification.add.success';
  static UPDATE_TEMPLATE_SUCCESS = 'template.notification.update.success';
  static DELETE_TEMPLATE_TITLE = 'template.notification.delete.title';
  static DELETE_TEMPLATE_MESSAGES = 'template.notification.delete.messages';
  static DELETE_TEMPLATE_SUCCESS = 'template.notification.delete.success';
  static ADD_TEMPLATE_TITLE = 'template.title.add';
  static UPDATE_TEMPLATE_TITLE = 'template.title.update';
  static PREVIEW_TEMPLATE_TITLE = 'template.title.preview';
  static ADD_DISCORDANCE_TITLE = 'discordance.title.add';


  static ADD_REPORT_SUCCESS = 'report.notification.add.success';
  static UPDATE_REPORT_SUCCESS = 'report.notification.update.success';
  static CHANGE_TEMPLATE_SUCCESS =
    'report.notification.change-template.success';

  static DELETE_GROUP_MESSAGES = 'template.group.notification.delete.messages';
  static DELETE_GROUP_TITLE = 'template.group.notification.delete.title';
  static DELETE_SUBGROUP_MESSAGES =
    'template.subgroup.notification.delete.messages';
  static DELETE_SUBGROUP_TITLE = 'template.subgroup.notification.delete.title';
  static UPDATE_GROUP_TYPE_MESSAGES =
    'template.group.notification.edit.type.messages';
  static UPDATE_GROUP_TYPE_TITLE =
    'template.group.notification.edit.type.title';

  static ADD_ANSWER_SUCCESS = 'answer.notification.add.success';

  static ADD_DISCORDANCE_SUCCESS = 'discordance.notification.add.success';


}

export const BASE_API_URL_DEV = 'https://akredito.rs/api/v1/';
export const BASE_API_URL_PROD = 'https://akredito.rs/api/v1/';
export const SUCCESS = 'success';
export const ERROR = 'errorMessages';
export const GROUP_TYPE_GROUP_WITH_SUBLIST = 'GROUP_WITH_SUBLIST';
export const GROUP_TYPE_TABLE = 'TABLE';
export const ANSWER_TYPE_SUFFIX = 'ANSWERTYPE';
export const ANSWER_TYPE_JSON = 'JSON';
export const ANSWER_TYPE_MUNICIPALITY = 'MUNICIPALITY';


export const REGULAR_ELEVATOR_CONTROL = 'REGULAR_ELEVATOR_CONTROL';
export const EXTRAORDINARY_ELEVATOR_CONTROL = 'EXTRAORDINARY_ELEVATOR_CONTROL';
export const FINAL_ELECTRIC_ELEVATOR_CONTROL =
  'FINAL_ELECTRIC_ELEVATOR_CONTROL';
export const FINAL_HYDRAULIC_ELEVATOR_CONTROL =
  'FINAL_HYDRAULIC_ELEVATOR_CONTROL';
export const SINGLE_ELECTRIC_ELEVATOR_VERIFICATION =
  'SINGLE_ELECTRIC_ELEVATOR_VERIFICATION';
export const SINGLE_HYDRAULIC_ELEVATOR_VERIFICATION =
  'SINGLE_HYDRAULIC_ELEVATOR_VERIFICATION';
export const SPEED_LIMITER_IN_USE_CONTROL = 'SPEED_LIMITER_IN_USE_CONTROL';
export const SPEED_LIMITER_BEFORE_USE_CONTROL =
  'SPEED_LIMITER_BEFORE_USE_CONTROL';
export const ESCALATOR_BEF0RE_USE_CONTROL = 'ESCALATOR_BEF0RE_USE_CONTROL';
export const ESCALATOR_PERIODIC_IN_USE_CONTROL =
  'ESCALATOR_PERIODIC_IN_USE_CONTROL';
export const LOW_LIFT_ELEVATOR_PERIODIC_CONTROL =
  'LOW_LIFT_ELEVATOR_PERIODIC_CONTROL';
export const FINAL_MLR_ELEVATOR_CONTROL = 'FINAL_MLR_ELEVATOR_CONTROL';
export const SINGLE_MLR_ELEVATOR_VERIFICATION =
  'SINGLE_MLR_ELEVATOR_VERIFICATION';
export const TRACKWAY_BEF0RE_USE_CONTROL = 'TRACKWAY_BEF0RE_USE_CONTROL';
export const TRACKWAY_PERIODIC_CONTROL = 'TRACKWAY_PERIODIC_CONTROL';
export const COMPANY = 'COMPANY';

export const TECH_DATA = 'ТЕХНИЧКИ ПОДАЦИ';
export const MANUFACTURER_KEY = 'MANUFACTURER';
export const APPLICANT_KEY = 'APPLICANT';
export const EXPIRY_TYPE_EXACT_DATE = 'EXACT_DATE';
