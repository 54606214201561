import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import { CommonLabels, FieldNames } from '../../../common/utils/constants';
import { Discordance } from '../../../common/model/discordance';
import * as ReportActions from '../../../store/report/actions/report.actions';
import { selectIsModalOpen } from '../../../store/report/selectors/report.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-discordance',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddDiscordanceComponent implements OnInit, OnDestroy {
  form: FormGroup;
  discordance: Discordance;
  modalToggleSubscription: Subscription;

  constructor(private dialogRef: MatDialogRef<AddDiscordanceComponent>,
              private formBuilder: FormBuilder,
              private store$: Store<AppState>,
              @Inject(MAT_DIALOG_DATA)
              public data: { title: string }) {
  }

  ngOnInit() {
    this.initForm();
    this.store$.dispatch(ReportActions.toggleModal({ isOpen: true }));

    this.modalToggleSubscription = this.store$
      .pipe(select(selectIsModalOpen))
      .subscribe((data) => {
        if (!data) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.modalToggleSubscription.unsubscribe();
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.DISCORDANCE_TEXT]: [CommonLabels.EMPTY],
      [FieldNames.DISCORDANCE_BIG_PROBLEM]: [false],
    });
  }

  save() {
    this.discordance = this.form.value;
    this.store$.dispatch(ReportActions.addDiscordance({ discordance: this.discordance }));
  }

  close() {
    this.dialogRef.close();
  }

}
