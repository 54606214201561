import { createReducer, on } from '@ngrx/store';
import { INIT_TEMPLATE_STATE, TemplateState } from '../state/template.state';
import {
  getGroupTypesSuccess,
  getReportTypesSuccess,
  getTemplatesDataTablesSuccess,
  getTemplatesSuccess,
  getTemplateSuccess,
  removeTemplate,
  TemplateActions,
} from '../actions/template.actions';


const reducers = createReducer(
  INIT_TEMPLATE_STATE,
  on(getTemplatesSuccess, (state, { templates }) => ({
    ...state,
    templates,
    templatesDataTables: {
      ...state.templatesDataTables,
      data: templates,
    },
  })),
  on(getTemplateSuccess, (state, { template }) => ({
    ...state,
    selectedTemplate: template,
  })),
  on(getTemplatesDataTablesSuccess, (state, { dataTableResponse }) => ({
    ...state,
    templatesDataTables: dataTableResponse,
  })),
  on(getReportTypesSuccess, (state, { types }) => ({
    ...state,
    reportTypes: types,
  })),
  on(getGroupTypesSuccess, (state, { types }) => ({
    ...state,
    groupTypes: types,
  })),
  on(removeTemplate, (state) => ({
    ...state,
    selectedTemplate: null,
  })),
);

export function templateReducers(state: TemplateState | undefined, action: TemplateActions) {
  return reducers(state, action);
}
