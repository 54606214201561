import { createAction, props, union } from '@ngrx/store';
import { TemplateActionsNames } from '../../../common/utils/constants';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Template } from '../../../common/model/template';

export const getTemplates = createAction(
  TemplateActionsNames.GET_TEMPLATES,
  props<{ withDeprecated: boolean }>(),
);
export const getTemplatesSuccess = createAction(
  TemplateActionsNames.GET_TEMPLATES_SUCCESS,
  props<{ templates: Template[] }>(),
);
export const getTemplatesDataTables = createAction(
  TemplateActionsNames.GET_TEMPLATES_DATA_TABLES,
  props<{ dataTablesParameters: any; callback: any }>(),
);
export const getTemplatesDataTablesSuccess = createAction(
  TemplateActionsNames.GET_TEMPLATES_DATA_TABLES_SUCCESS,
  props<{ dataTableResponse: DataTablesResponse; callback: any }>(),
);
export const addTemplate = createAction(
  TemplateActionsNames.ADD_TEMPLATE,
  props<{ template: Template }>(),
);
export const getTemplate = createAction(
  TemplateActionsNames.GET_TEMPLATE,
  props<{ id: number }>(),
);
export const getTemplateSuccess = createAction(
  TemplateActionsNames.GET_TEMPLATE_SUCCESS,
  props<{ template: Template }>(),
);
export const updateTemplate = createAction(
  TemplateActionsNames.UPDATE_TEMPLATE,
  props<{ template: Template }>(),
);
export const deleteTemplate = createAction(
  TemplateActionsNames.DELETE_TEMPLATE,
  props<{ id: number }>(),
);
export const getReportTypes = createAction(
  TemplateActionsNames.GET_REPORT_TYPES,
);
export const getReportTypesSuccess = createAction(
  TemplateActionsNames.GET_REPORT_TYPES_SUCCESS,
  props<{ types: Map<string, string> }>(),
);
export const getGroupTypes = createAction(TemplateActionsNames.GET_GROUP_TYPES);
export const getGroupTypesSuccess = createAction(
  TemplateActionsNames.GET_GROUP_TYPES_SUCCESS,
  props<{ types: Map<string, string> }>(),
);
export const removeTemplate = createAction(
  TemplateActionsNames.REMOVE_TEMPLATE,
);
export const navigateToTemplateList = createAction(
  TemplateActionsNames.NAVIGATE_TO_TEMPLATE_LIST,
);

const all = union({
  getTemplates,
  getTemplatesSuccess,
  getTemplatesDataTables,
  getTemplatesDataTablesSuccess,
  addTemplate,
  getTemplate,
  getTemplateSuccess,
  updateTemplate,
  deleteTemplate,
  getReportTypes,
  getReportTypesSuccess,
  getGroupTypes,
  getGroupTypesSuccess,
  removeTemplate,
  navigateToTemplateList,
});
export type TemplateActions = typeof all;
