import { createAction, props, union } from '@ngrx/store';
import { QuestionActionsNames } from '../../../common/utils/constants';
import { Question } from '../../../common/model/question';
import { DataTablesResponse } from '../../../common/model/datatables_response';

export const getQuestions = createAction(QuestionActionsNames.GET_QUESTIONS);
export const getQuestionsSuccess = createAction(QuestionActionsNames.GET_QUESTION_SUCCESS, props<{ questions: Question[] }>());
export const getQuestionsDataTables = createAction(QuestionActionsNames.GET_QUESTIONS_DATA_TABLES, props<{ dataTablesParameters: any, callback: any }>());
export const getQuestionsDataTablesSuccess = createAction(QuestionActionsNames.GET_QUESTIONS_DATA_TABLES_SUCCESS, props<{ dataTableResponse: DataTablesResponse, callback: any }>());
export const addQuestion = createAction(QuestionActionsNames.ADD_QUESTION, props<{ question: Question }>());
export const addQuestionSuccess = createAction(QuestionActionsNames.ADD_QUESTION_SUCCESS, props<{ question: Question }>());
export const toggleModal = createAction(QuestionActionsNames.TOGGLE_MODAL, props<{ isOpen: boolean }>());
export const getQuestion = createAction(QuestionActionsNames.GET_QUESTION, props<{ id: number }>());
export const getQuestionSuccess = createAction(QuestionActionsNames.GET_QUESTIONS_SUCCESS, props<{ question: Question }>());
export const updateQuestion = createAction(QuestionActionsNames.UPDATE_QUESTION, props<{ question: Question }>());
export const deleteQuestion = createAction(QuestionActionsNames.DELETE_QUESTION, props<{ id: number }>());
export const getAnswerTypes = createAction(QuestionActionsNames.GET_ANSWER_TYPES);
export const getAnswerTypesSuccess = createAction(QuestionActionsNames.GET_ANSWER_TYPES_SUCCESS, props<{ types: Map<string, string> }>());

const all = union(
  {
    getQuestions,
    getQuestionsSuccess,
    getQuestionsDataTables,
    getQuestionsDataTablesSuccess,
    addQuestion,
    toggleModal,
    getQuestion,
    getQuestionSuccess,
    updateQuestion,
    deleteQuestion,
    getAnswerTypes,
    getAnswerTypesSuccess,
  },
);
export type QuestionActions = typeof all;
