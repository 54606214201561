import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatSelectChange } from '@angular/material';
import { TemplateQuestionListComponent } from '../../shared/template-question-list/template-question-list.component';
import { Question } from '../../../common/model/question';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as TemplateActions from '../../../store/template/actions/template.actions';
import { AppState } from '../../../store/app/state/app.state';
import { selectGroupTypes, selectReportTypes, selectTemplate } from '../../../store/template/selectors/template.selectors';
import { Group, GroupQuestion, HeaderItem, Template } from '../../../common/model/template';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { CommonLabels, FieldNames, GROUP_TYPE_GROUP_WITH_SUBLIST, GROUP_TYPE_TABLE, MessagesKey } from '../../../common/utils/constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit, OnDestroy {
  form: FormGroup;
  groupArray: FormArray;
  reportTypes: Observable<Map<string, string>>;
  groupTypes: Observable<Map<string, string>>;
  template: Template = {} as Template;
  groupWithSubgroup = GROUP_TYPE_GROUP_WITH_SUBLIST;
  table = GROUP_TYPE_TABLE;
  oldGroupTypes: string[] = [];
  selectedTemplateSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private store$: Store<AppState>, private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.initForm();
    this.reportTypes = this.store$.pipe(select(selectReportTypes));
    this.store$.dispatch(TemplateActions.getReportTypes());

    this.groupTypes = this.store$.pipe(select(selectGroupTypes));
    this.store$.dispatch(TemplateActions.getGroupTypes());

    this.groupArray = this.form.get(FieldNames.TEMPLATE_GROUPS) as FormArray;

    const id = +this.activeRoute.snapshot.params['id'];
    if (id) {
      this.store$.dispatch(TemplateActions.getTemplate({ id: id }));
      this.selectedTemplateSubscription = this.store$.pipe(select(selectTemplate)).subscribe((template) => {
        if (template) {
          this.populateForm(template);
        }
      });
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.TEMPLATE_ID]: [CommonLabels.EMPTY],
      [FieldNames.TEMPLATE_NAME]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_REPORT_TYPE]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUPS]: this.formBuilder.array([]),
    },
    );
  }

  addGroup() {
    this.groupArray.push(this.createGroupArray());
    this.oldGroupTypes.push(CommonLabels.EMPTY);
  }

  createGroupArray() {
    return this.formBuilder.group({
      [FieldNames.TEMPLATE_GROUP_ID]: [CommonLabels.EMPTY],
      [FieldNames.TEMPLATE_GROUP_NAME]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUP_DESCRIPTION]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUP_TYPE]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUP_QUESTIONS]: this.formBuilder.array([]),
      [FieldNames.TEMPLATE_GROUP_SUBGROUPS]: this.formBuilder.array([]),
    });
  }

  addQuestion(index) {
    const groupQuestionArray = this.groupArray.controls[index].get(FieldNames.TEMPLATE_GROUP_QUESTIONS) as FormArray;
    const dialogRef = this.openModal(groupQuestionArray.getRawValue());
    dialogRef.afterClosed().subscribe((selectedQuestions: Question[]) => {
      if (selectedQuestions) {
        groupQuestionArray.clear();
        selectedQuestions.map((question) => {
          groupQuestionArray.push(this.formBuilder.group({
            [FieldNames.QUESTION_ID]: question.id,
            [FieldNames.QUESTION_TEXT]: question.text,
            [FieldNames.QUESTION_ADDITIONAL_INFO]: question.additionalInfo,
          }));

        });
      }
    });
  }


  dropQuestion(index, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getQuestionArray(index).controls, event.previousIndex, event.currentIndex);
  }

  dropSubCategoryQuestion(groupIndex, subgroupIndex, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getSubcategoryQuestionArray(groupIndex, subgroupIndex).controls, event.previousIndex, event.currentIndex);
  }

  private getQuestionArray(index: any) {
    return this.groupArray.controls[index].get(FieldNames.TEMPLATE_GROUP_QUESTIONS) as FormArray;
  }

  private openModal(data) {
    return this.dialog.open(TemplateQuestionListComponent, {
      width: '90%',
      height: '90%',
      data: data,
    });
  }

  addSubgroup(index) {
    const subgroupArray = this.groupArray.controls[index].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    subgroupArray.push(this.createSubgroupArray());
  }

  changedGroupType(event: MatSelectChange, groupIndex) {
    this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_TYPE).setValue(this.oldGroupTypes[groupIndex]);
    if ((this.getQuestionArray(groupIndex).length > 0 && (event.value === this.groupWithSubgroup || event.value === this.table)) || (this.getSubgroupsArray(groupIndex).length > 0)) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          titleKey: MessagesKey.UPDATE_GROUP_TYPE_TITLE,
          messagesKey: MessagesKey.UPDATE_GROUP_TYPE_MESSAGES,
        },
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          const subgroups = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
          subgroups.clear();
          const questions = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_QUESTIONS) as FormArray;
          questions.clear();
          this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_TYPE).setValue(event.value);
          this.oldGroupTypes[groupIndex] = event.value;
          if (this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_TYPE).value === this.table) {
            this.addSubGroupForTable(groupIndex);
          }
        }
      });
    } else {
      this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_TYPE).setValue(event.value);
      this.oldGroupTypes[groupIndex] = event.value;
      if (this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_TYPE).value === this.table) {
        this.addSubGroupForTable(groupIndex);
      }
    }


  }

  private createSubgroupArray() {
    return this.formBuilder.group({
      [FieldNames.TEMPLATE_GROUP_ID]: [CommonLabels.EMPTY],
      [FieldNames.TEMPLATE_GROUP_NAME]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUP_DESCRIPTION]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUP_QUESTIONS]: this.formBuilder.array([]),
      [FieldNames.TEMPLATE_GROUP_HEADER_ITEMS]: this.formBuilder.array([]),
    });
  }

  addQuestionToSubgroup(groupIndex, subgroupIndex) {
    const subgroupArray = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    const subgroupQuestionArray = subgroupArray.controls[subgroupIndex].get(FieldNames.TEMPLATE_GROUP_QUESTIONS) as FormArray;

    const dialogRef = this.openModal(subgroupQuestionArray.getRawValue());
    dialogRef.afterClosed().subscribe((selectedQuestions: Question[]) => {
      if (selectedQuestions) {
        subgroupQuestionArray.clear();
        selectedQuestions.map((question) => {
          subgroupQuestionArray.push(this.formBuilder.group({
            [FieldNames.QUESTION_ID]: question.id,
            [FieldNames.QUESTION_TEXT]: question.text,
            [FieldNames.QUESTION_ADDITIONAL_INFO]: question.additionalInfo,
          }));

        });
      }
    });

  }

  private getSubcategoryQuestionArray(groupIndex, subgroupIndex) {
    const subCategoryArray = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    const subCategoryQuestionsArray = subCategoryArray.controls[subgroupIndex].get(FieldNames.TEMPLATE_GROUP_QUESTIONS) as FormArray;
    return subCategoryQuestionsArray;
  }

  saveTemplate() {
    const templateForm = this.form.getRawValue();
    this.template.id = templateForm.id;
    this.template.name = templateForm.name;
    this.template.reportType = templateForm.reportType;
    this.template.groups = templateForm.groups.map((groupForm, groupIndex) =>
      ({
        id: groupForm.id,
        index: groupIndex + 1,
        name: groupForm.name,
        description: groupForm.description,
        groupType: groupForm.groupType,
        questions: groupForm.questions.map((question, questionIndex) => ({
          index: questionIndex + 1,
          questionId: question.id,
          visible: true,
        } as GroupQuestion)),
        subgroups: groupForm.subgroups.map((subgroup, subgroupIndex) => ({
          id: subgroup.id,
          name: subgroup.name,
          description: subgroup.description,
          groupType: subgroup.groupType,
          index: subgroupIndex + 1,
          questions: subgroup.questions.map((question, questionIndex) => ({
            index: questionIndex + 1,
            questionId: question.id,
            visible: true,
          } as GroupQuestion)),
          headerItems: subgroup.headerItems.map((headerItem, headerItemIndex) => ({
            id: headerItem.id,
            value: headerItem.value,
            type: headerItem.answerType,
            index: headerItemIndex + 1,
          } as HeaderItem)),
        } as Group)),
      } as Group));
    if (this.template.id) {
      this.store$.dispatch(TemplateActions.updateTemplate({ template: this.template }));
    } else {
      this.store$.dispatch(TemplateActions.addTemplate({ template: this.template }));
    }
  }

  dropGroup(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.groupArray.controls, event.previousIndex, event.currentIndex);
  }

  dropSubgroup(index, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getSubgroupsArray(index).controls, event.previousIndex, event.currentIndex);
  }

  private getSubgroupsArray(index: any) {
    return this.groupArray.controls[index].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
  }

  deleteGroup(groupIndex) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        titleKey: MessagesKey.DELETE_GROUP_TITLE,
        messagesKey: MessagesKey.DELETE_GROUP_MESSAGES,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.groupArray.removeAt(groupIndex);
        this.oldGroupTypes.slice(groupIndex, 1);
      }
    });
  }

  deleteSubgroup(groupIndex, subgroupIndex) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        titleKey: MessagesKey.DELETE_SUBGROUP_TITLE,
        messagesKey: MessagesKey.DELETE_SUBGROUP_MESSAGES,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const subgroups = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
        subgroups.removeAt(subgroupIndex);
      }
    });
  }

  private addSubGroupForTable(groupIndex) {
    const subGroupArray = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    subGroupArray.push(this.createSubgroupArray());

  }


  addHeaderToSubGroup(groupIndex, subgroupIndex) {
    const subGroupArray = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    const headerItemsArray = subGroupArray.controls[subgroupIndex].get(FieldNames.TEMPLATE_GROUP_HEADER_ITEMS) as FormArray;
    headerItemsArray.push(this.createHeaderItemsGroup());
  }

  private createHeaderItemsGroup() {
    return this.formBuilder.group({
      [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_ID]: [CommonLabels.EMPTY],
      [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_VALUE]: [CommonLabels.EMPTY, Validators.required],
      [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_ANSWER_TYPE]: [CommonLabels.EMPTY, Validators.required],
    });
  }

  deleteHeaderItem(groupIndex, subgroupIndex, headerItemIndex) {
    const subgroups = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    const headers = subgroups.controls[subgroupIndex].get(FieldNames.TEMPLATE_GROUP_HEADER_ITEMS) as FormArray;
    headers.removeAt(headerItemIndex);
  }

  dropSubCategoryHeaderItems(groupIndex, subgroupIndex, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getSubcategoryHeadersArray(groupIndex, subgroupIndex).controls, event.previousIndex, event.currentIndex);
  }

  private getSubcategoryHeadersArray(groupIndex: any, subgroupIndex: any) {
    const subCategoryArray = this.groupArray.controls[groupIndex].get(FieldNames.TEMPLATE_GROUP_SUBGROUPS) as FormArray;
    const subCategoryQuestionsArray = subCategoryArray.controls[subgroupIndex].get(FieldNames.TEMPLATE_GROUP_HEADER_ITEMS) as FormArray;
    return subCategoryQuestionsArray;
  }

  ngOnDestroy(): void {
    if (this.selectedTemplateSubscription) {
      this.selectedTemplateSubscription.unsubscribe();
    }
    this.store$.dispatch(TemplateActions.removeTemplate());
  }

  private populateForm(template: Template) {
    this.form.get(FieldNames.TEMPLATE_ID).setValue(template.id);
    this.form.get(FieldNames.TEMPLATE_NAME).setValue(template.name);
    this.form.get(FieldNames.TEMPLATE_REPORT_TYPE).setValue(template.reportType);
    if (template.groups) {
      this.populateGroupArrayForm(template.groups);
    }
  }

  private populateGroupArrayForm(groups: Group[]) {
    groups.map((group) => {
      this.groupArray.push(this.populateGroupForm(group));
    });
  }

  private populateGroupForm(group: Group) {
    return this.formBuilder.group({
      [FieldNames.TEMPLATE_GROUP_ID]: [group.id],
      [FieldNames.TEMPLATE_GROUP_NAME]: [group.name, Validators.required],
      [FieldNames.TEMPLATE_GROUP_DESCRIPTION]: [group.description, Validators.required],
      [FieldNames.TEMPLATE_GROUP_TYPE]: [group.groupType, Validators.required],
      [FieldNames.TEMPLATE_GROUP_QUESTIONS]: group.questions ? this.populateQuestionForm(group.questions) : this.formBuilder.array([]),
      [FieldNames.TEMPLATE_GROUP_SUBGROUPS]: group.subgroups ? this.populateSubGroupForm(group.subgroups) : this.formBuilder.array([]),
    });
    this.oldGroupTypes.push(group.groupType);
  }

  private populateQuestionForm(questions: GroupQuestion[]): FormArray {
    const questionsArray = this.formBuilder.array([]);
    questions.map((question) => {
      questionsArray.push(this.formBuilder.group({
        [FieldNames.QUESTION_ID]: question.questionId,
        [FieldNames.QUESTION_TEXT]: question.value,
        [FieldNames.QUESTION_ADDITIONAL_INFO]: question.value,
      }));
    });
    return questionsArray;
  }

  private populateSubGroupForm(subgroups: Group[]): FormArray {
    const subGroupArray = this.formBuilder.array([]);
    subgroups.map((subgroup) => {
      subGroupArray.push(this.formBuilder.group({
        [FieldNames.TEMPLATE_GROUP_ID]: [subgroup.id],
        [FieldNames.TEMPLATE_GROUP_NAME]: [subgroup.name, Validators.required],
        [FieldNames.TEMPLATE_GROUP_DESCRIPTION]: [subgroup.description, Validators.required],
        [FieldNames.TEMPLATE_GROUP_QUESTIONS]: subgroup.questions ? this.populateQuestionForm(subgroup.questions) : this.formBuilder.array([]),
        [FieldNames.TEMPLATE_GROUP_HEADER_ITEMS]: subgroup.headerItems ? this.populateHeaderItemsForm(subgroup.headerItems) : this.formBuilder.array([]),
      }));
    });
    return subGroupArray;
  }

  private populateHeaderItemsForm(headerItems: HeaderItem[]) {
    const headerItemsArray = this.formBuilder.array([]);
    headerItems.map((headerItem) => {
      headerItemsArray.push(this.formBuilder.group({
        [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_ID]: [headerItem.id],
        [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_VALUE]: [headerItem.value, Validators.required],
        [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_ANSWER_TYPE]: [headerItem.type, Validators.required],
      }));
    });
    return headerItemsArray;
  }
}
