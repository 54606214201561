import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatInput, MatPaginator, MatSelect, MatTableDataSource } from '@angular/material';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subscription } from 'rxjs';
import { Question } from '../../../common/model/question';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import {
  selectAnswerTypes,
  selectLastAddedQuestion,
  selectQuestionsDataTables,
} from '../../../store/question/selectors/question.selectors';
import * as QuestionActions from '../../../store/question/actions/question.actions';
import { FieldNames, MessagesKey } from '../../../common/utils/constants';
import { AddQuestionComponent } from '../../question/add/add.component';

@Component({
  selector: 'app-template-question-list',
  templateUrl: './template-question-list.component.html',
  styleUrls: ['./template-question-list.component.scss'],
})
export class TemplateQuestionListComponent implements OnInit, OnDestroy {
  @ViewChild(MatInput, { static: false }) searchInput: MatInput;
  @ViewChild(DataTableDirective, { static: false })
  dataTableElement: DataTableDirective;
  @ViewChild(MatSelect, { static: false }) type: MatSelect;
  questions: Observable<Question[]>;
  answerTypes: Observable<Map<string, string>>;
  dtOptions: any;
  selectedQuestions: Question[] = [];
  dataSource: MatTableDataSource<Question> = new MatTableDataSource<Question>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['text', 'additionalInfo', 'actions'];
  newQuestionSubscription: Subscription;
  addQuestionActionCall = false;

  constructor(
    private store$: Store<AppState>,
    private dialogRef: MatDialogRef<TemplateQuestionListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Question[],
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.selectedQuestions = this.data;
    this.initDataTable();
    this.questions = this.store$.pipe(select(selectQuestionsDataTables));
    this.answerTypes = this.store$.pipe(select(selectAnswerTypes));
    this.store$.dispatch(QuestionActions.getAnswerTypes());
    this.paginator._changePageSize(10);
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.selectedQuestions;
    setTimeout(() => this.paginator.lastPage(), 0);

    this.store$.pipe(select(selectLastAddedQuestion)).subscribe((data) => {
      if (data && this.addQuestionActionCall) {
        this.addQuestion(data);
      }
    });
  }

  initDataTable() {
    this.dtOptions = {
      serverSide: true,
      processing: false,
      dom: 'lpt',
      // pageLength: 5,
      language: {
        lengthMenu: '_MENU_ ',
        paginate: {
          previous: '&lt;',
          next: '&gt;',
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        let ids = [];
        this.selectedQuestions.map((question) => {
          ids.push(question.id);
        });
        dataTablesParameters.columns[0].search[
          'value'
          ] = this.searchInput.value;
        dataTablesParameters.columns[1].search['value'] = this.type.value;
        dataTablesParameters.columns[2].search['value'] = ids.join('+');
        this.store$.dispatch(
          QuestionActions.getQuestionsDataTables({
            dataTablesParameters,
            callback,
          }),
        );
      },
      columnDefs: [
        {
          searchable: false,
          targets: [0, 1, 2],
        },
        {
          width: '50%', targets: 0,
        },
      ],
      columns: [
        { data: FieldNames.QUESTION_TEXT, orderable: false },
        { data: FieldNames.QUESTION_TEXT, orderable: false },
        { data: FieldNames.QUESTION_ADDITIONAL_INFO, orderable: false },
        { data: FieldNames.QUESTION_ANSWER_TYPE, orderable: false },
      ],
    };
  }

  search() {
    this.draw();
  }

  draw() {
    if (!!this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  addQuestion(question: Question) {
    this.selectedQuestions.push(question);
    this.dataSource.data = this.selectedQuestions;
    setTimeout(() => this.paginator.lastPage(), 0);
    this.draw();
  }

  ngOnDestroy(): void {
  }

  remove(id) {
    const index = this.selectedQuestions.findIndex((item) => item.id === id);
    this.selectedQuestions.splice(index, 1);
    this.dataSource.data = this.selectedQuestions;
    this.draw();
  }

  close() {
    this.dialogRef.close();
  }

  addQuestions() {
    this.dialogRef.close(this.selectedQuestions);
  }

  openAddModalQuestion() {
    this.addQuestionActionCall = true;
    const dialogRef = this.dialog.open(AddQuestionComponent, {
      disableClose: true,
      data: { id: null, disable: false, title: MessagesKey.ADD_QUESTION_TITLE },
      width: '50%',
    });
  }
}
