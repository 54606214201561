import { Paths, UserActionNames } from '../../../common/utils/constants';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserAction from '../actions/user.actions';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../common/api/auth.service';
import { User } from '../../../common/model/user.model';
import * as SharedAction from '../../shared/actions/shared.actions';

@Injectable()
export class UserEffects {
  constructor(
    private action$: Actions,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActionNames.LOGIN),
      switchMap((data: any) => this.authService.login(data).pipe(
        switchMap((user: User) =>
          of(
            UserAction.loginSuccess({ user }),
            UserAction.loginSuccessNavigate(),
          ),
        ),
        catchError((error) => of(SharedAction.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
  navigateOnLogin$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(UserActionNames.LOGIN_SUCCESS_NAVIGATE),
        tap(() => {
          this.router.navigate([Paths.HOME]);
        }),
      ),
    { dispatch: false },
  );

  navigateToLoginPage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(UserActionNames.NAVIGATE_TO_LOGIN_PAGE),
        tap(() => {
          this.router.navigate([Paths.LOGIN]);
        }),
      ),
    { dispatch: false },
  );
  logout$ = createEffect(() =>
    this.action$.pipe(
      ofType(UserActionNames.LOGOUT),
      switchMap(() => this.authService.logout()),
      switchMap(() =>
        of(UserAction.logoutSuccess(), UserAction.navigateToLoginPage()),
      ),
    ),
  );
}
