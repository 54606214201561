import { DataTablesResponse } from '../../../common/model/datatables_response';
import { Question } from '../../../common/model/question';

export interface QuestionState {
  questionsDataTables: DataTablesResponse;
  questions: Question[];
  isModalOpen: boolean;
  selectedQuestion: Question;
  answerTypes: Map<string, string>;
  lastAddedQuestion: Question;
}

export const INIT_QUESTION_STATE: QuestionState = {
  questionsDataTables: {
    data: [],
    draw: 0,
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  questions: [],
  isModalOpen: false,
  selectedQuestion: null,
  answerTypes: new Map<string, string>(),
  lastAddedQuestion: null,
};
