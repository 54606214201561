import { AppState } from '../../app/state/app.state';

export const selectCompanies = (state: AppState) => state.company.companies;
export const selectCompaniesDataTables = (state: AppState) =>
  state.company.companiesDataTables.data;
export const selectIsModalOpen = (state: AppState) => state.company.isModalOpen;
export const selectCompany = (state: AppState) => state.company.selectedCompany;
export const selectCompanyTypes = (state: AppState) => state.company.companyTypes;
export const selectLastAddedCompany = (state: AppState) => state.company.lastAddedCompany;
export const selectFilteredCompanies = (state: AppState) => state.company.filteredCompanies;
