import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EnumNames, MessagesKey, ProductActionNames } from '../../../common/utils/constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProductActions from '../../product/actions/product.actions';
import * as SharedActions from '../../shared/actions/shared.actions';
import { ProductService } from '../../../common/api/product.service';
import { Product } from '../../../common/model/product';
import { DataTablesResponse } from '../../../common/model/datatables_response';
import { TypeService } from '../../../common/api/type.service';

@Injectable()
export class ProductEffects {
  constructor(private action$: Actions, private productService: ProductService, private typeService: TypeService) {
  }

  getProducts$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.GET_PRODUCTS),
      switchMap(() => this.productService.getProducts().pipe(
        switchMap((products: Product[]) => of(ProductActions.getProductsSuccess({ products: products }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  addProduct$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.ADD_PRODUCT),
      switchMap((props: { product: Product }) => this.productService.addProduct(props.product).pipe(
        switchMap((data: Product) => of(ProductActions.toggleModal({ isOpen: false }), ProductActions.addProductSuccess({ product: data }), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.ADD_PRODUCT_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
  getProduct$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.GET_PRODUCT),
      switchMap((data: { id: number }) => this.productService.getProduct(data.id).pipe(
        switchMap((product: Product) => of(ProductActions.getProductSuccess({ product: product }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  updateProduct$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.UPDATE_PRODUCT),
      switchMap((props: { product: Product }) => this.productService.updateProduct(props.product).pipe(
        switchMap(() => of(ProductActions.toggleModal({ isOpen: false }), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.UPDATE_PRODUCT_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
  deleteProduct$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.DELETE_PRODUCT),
      switchMap((props: { id: number }) => this.productService.deleteProduct(props.id).pipe(
        switchMap(() => of(ProductActions.getProducts(), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.DELETE_PRODUCT_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  getProductsDataTables$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.GET_PRODUCTS_DATA_TABLES),
      switchMap((data: { dataTablesParameters: any, callback: any }) => this.productService.getProductsDataTables(data.dataTablesParameters).pipe(
        switchMap((response: DataTablesResponse) => of(ProductActions.getProductsDataTablesSuccess(
          { dataTableResponse: response, callback: data.callback },
          )),
        )),
      ),
    ));

  getProductsDataTablesSuccess$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.GET_PRODUCTS_DATA_TABLES_SUCCESS),
      map((data: { dataTableResponse: DataTablesResponse, callback: any }) => {
        data.callback({
          recordsTotal: data.dataTableResponse.recordsTotal,
          recordsFiltered: data.dataTableResponse.recordsFiltered,
          data: [],
        });
      }),
    ), { dispatch: false },
  );


  getProductTypes$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductActionNames.GET_PRODUCT_TYPES),
      switchMap(() => this.typeService.getTypesWithValue(EnumNames.PRODUCT_TYPE).pipe(
        switchMap((productTypes: Map<string, string>) => of(ProductActions.getProductTypesSuccess({ types: productTypes }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
}
