import { createReducer, on } from '@ngrx/store';
import { INIT_QUESTION_STATE, QuestionState } from '../state/question.state';
import {
  addQuestionSuccess,
  getAnswerTypesSuccess,
  getQuestionsDataTablesSuccess,
  getQuestionsSuccess,
  getQuestionSuccess,
  QuestionActions,
  toggleModal,
} from '../actions/question.actions';


const reducers = createReducer(
  INIT_QUESTION_STATE,
  on(getQuestionsSuccess, (state, { questions }) => ({
    ...state,
    questions,
    questionsDataTables: {
      ...state.questionsDataTables,
      data: questions,
    },
  })),
  on(toggleModal, (state, { isOpen }) => ({
    ...state,
    isModalOpen: isOpen,
  })),
  on(getQuestionSuccess, (state, { question }) => ({
    ...state,
    selectedQuestion: question,
  })),
  on(getQuestionsDataTablesSuccess, (state, { dataTableResponse }) => ({
    ...state,
    questionsDataTables: dataTableResponse,
  })),
  on(getAnswerTypesSuccess, (state, { types }) => ({
    ...state,
    answerTypes: types,
  })),
  on(addQuestionSuccess, (state, { question }) => ({
    ...state,
    lastAddedQuestion: question,
  })),
);

export function questionReducers(state: QuestionState | undefined, action: QuestionActions) {
  return reducers(state, action);
}
