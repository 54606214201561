import { createReducer, on } from '@ngrx/store';
import { INIT_REPORT_STATE, ReportState } from '../state/report.state';
import {
  addDiscordanceSuccess,
  getAnswers,
  getAnswersSuccess,
  getDiscordanceSuccess,
  getExcelReportSuccess,
  getReportsDataTablesSuccess,
  getReportSuccess,
  getTypesSuccess,
  getTypesWithGroupSuccess,
  removeExcelReport,
  removeExpReport,
  removeReport,
  ReportActions,
  setLoading,
  toggleModal,
  getExportSuccess
} from '../actions/report.actions';
import { Discordance } from '../../../common/model/discordance';

const reducers = createReducer(
  INIT_REPORT_STATE,
  on(getTypesSuccess, (state, { types }) => ({
    ...state,
    types,
  })),
  on(getDiscordanceSuccess, (state, { discordance }) => ({
    ...state,
    discordanceList: discordance,
  })),
  on(getReportsDataTablesSuccess, (state, { dataTableResponse }) => ({
    ...state,
    reportsDataTables: dataTableResponse,
  })),
  on(getExcelReportSuccess, (state, { file }) => ({
    ...state,
    report: file,
  })),
  on(getExportSuccess, (state, { file }) => ({
    ...state,
    exp: file,
  })),
  on(removeExcelReport, (state) => ({
    ...state,
    report: null,
  })),
  on(removeExpReport, (state) => ({
    ...state,
    exp: null,
  })),
  on(getReportSuccess, (state, { report }) => ({
    ...state,
    selectedReport: report,
  })),
  on(removeReport, (state) => ({
    ...state,
    selectedReport: null,
  })),
  on(getAnswersSuccess, (state, { answers }) => ({
    ...state,
    answerList: answers,
    // loading: false,
  })),
  on(getTypesWithGroupSuccess, (state, { types }) => ({
    ...state,
    typesWithGroup: types,
  })),
  on(toggleModal, (state, { isOpen }) => ({
    ...state,
    isModalOpen: isOpen,
  })),
  on(getAnswers, (state) => ({
    ...state,
    loading: true,
  })),
  on(setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(addDiscordanceSuccess, (state, { discordance }) => ({
    ...state,
    discordanceList: getNewDiscordanceList(state.discordanceList, discordance),
  })),
);

function getNewDiscordanceList(discordances: Discordance[], discordance: Discordance) {
  const discordancesCopy = discordances.slice();
  discordancesCopy.push(discordance);
  return discordancesCopy;
}

export function reportReducers(state: ReportState | undefined, action: ReportActions) {
  return reducers(state, action);
}

