import { AuthApiEndpoints } from '../utils/constants';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  AUTH_URL = environment.apiUrl + AuthApiEndpoints.BASE_AUTH_URL;

  constructor(private http: HttpClient) {}

  login(loginRequest: { username: string; password: string }) {
    return this.http.post(this.AUTH_URL + AuthApiEndpoints.LOGIN, loginRequest);
  }

  logout() {
    return this.http.get(this.AUTH_URL + AuthApiEndpoints.LOGOUT, {
      responseType: 'text' as 'json',
    });
  }
}
