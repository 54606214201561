import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import { selectAnswerTypes } from '../../../store/question/selectors/question.selectors';
import * as ReportActions from '../../../store/report/actions/report.actions';
import { CommonLabels, FieldNames } from '../../../common/utils/constants';
import { DropdownAnswer } from '../../../common/model/dropdown_answer';
import * as QuestionActions from '../../../store/question/actions/question.actions';
import { selectIsModalOpen } from '../../../store/report/selectors/report.selectors';

@Component({
  selector: 'app-add-dropdown-answer',
  templateUrl: './add-dropdown-answer.component.html',
  styleUrls: ['./add-dropdown-answer.component.scss'],
})
export class AddDropdownAnswerComponent implements OnInit, OnDestroy {

  form: FormGroup;
  modalToggleSubscription: Subscription;
  dropdownAnswer: DropdownAnswer = {} as DropdownAnswer;
  answerTypes: Observable<Map<string, string>>;

  constructor(
    private dialogRef: MatDialogRef<AddDropdownAnswerComponent>,
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.answerTypes = this.store$.pipe(select(selectAnswerTypes));
    this.store$.dispatch(ReportActions.toggleModal({ isOpen: true }));
    this.store$.dispatch(QuestionActions.getAnswerTypes());

    this.modalToggleSubscription = this.store$
      .pipe(select(selectIsModalOpen))
      .subscribe((data) => {
        if (!data) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy() {
    if (this.modalToggleSubscription) {
      this.modalToggleSubscription.unsubscribe();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.DROPDOWN_ANSWER_VALUE]: [
        CommonLabels.EMPTY,
        Validators.required,
      ],
      [FieldNames.QUESTION_ANSWER_TYPE]: [
        CommonLabels.EMPTY,
        Validators.required,
      ],
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dropdownAnswer = this.form.value;
    this.store$.dispatch(
      ReportActions.addDropdownAnswer({ answer: this.dropdownAnswer }),
    );
  }
}
