import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { storageMetaReducerFactory } from '../../common/services/storage.metareducer';
import { LocalStorageService } from '../../common/services/local-storage.service';
import {
  GUI_CONFIG_TOKEN,
  GUI_LOCAL_STORAGE_KEY,
  GUI_STORAGE_KEYS,
} from './gui.tokens';
import { HttpClientModule } from '@angular/common/http';
import { guiReducers } from './reducers/gui.reducers';
import { GuiEffects } from './effects/gui.effects';

export function getGuiConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
) {
  return {
    metaReducers: [
      storageMetaReducerFactory(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('gui', guiReducers, GUI_CONFIG_TOKEN),
    EffectsModule.forFeature([GuiEffects]),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    GuiEffects,
    { provide: GUI_LOCAL_STORAGE_KEY, useValue: '__gui_storage__' },
    { provide: GUI_STORAGE_KEYS, useValue: ['language'] },
    {
      provide: GUI_CONFIG_TOKEN,
      deps: [GUI_STORAGE_KEYS, GUI_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getGuiConfig,
    },
  ],
  entryComponents: [],
})
export class GuiStateModule {}
