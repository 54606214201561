import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EnumNames, MessagesKey, QuestionActionsNames } from '../../../common/utils/constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SharedActions from '../../shared/actions/shared.actions';
import { TypeService } from '../../../common/api/type.service';

import * as QuestionActions from '../../../store/question/actions/question.actions';
import { Question } from '../../../common/model/question';
import { QuestionService } from '../../../common/api/question.service';
import { DataTablesResponse } from '../../../common/model/datatables_response';

@Injectable()
export class QuestionEffects {

  constructor(private action$: Actions, private typeService: TypeService, private questionService: QuestionService) {
  }

  getQuestions$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.GET_QUESTIONS),
      switchMap(() => this.questionService.getQuestions().pipe(
        switchMap((questions: Question[]) => of(QuestionActions.getQuestionsSuccess({ questions }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  addQuestion$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.ADD_QUESTION),
      switchMap((props: { question: Question }) => this.questionService.addQuestion(props.question).pipe(
        switchMap((data: Question) => of(QuestionActions.toggleModal({ isOpen: false }), QuestionActions.addQuestionSuccess({ question: data }), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.ADD_QUESTION_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );


  getAnswerTypes$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.GET_ANSWER_TYPES),
      switchMap(() => this.typeService.getTypesWithValue(EnumNames.ANSWER_TYPE).pipe(
        switchMap((answerTypes: Map<string, string>) => of(QuestionActions.getAnswerTypesSuccess({ types: answerTypes }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  getQuestion$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.GET_QUESTION),
      switchMap((data: { id: number }) => this.questionService.getQuestion(data.id).pipe(
        switchMap((quesiton: Question) => of(QuestionActions.getQuestionSuccess({ question: quesiton }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  updateQuesiton$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.UPDATE_QUESTION),
      switchMap((props: { question: Question }) => this.questionService.updateQuestion(props.question).pipe(
        switchMap(() => of(QuestionActions.toggleModal({ isOpen: false }), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.UPDATE_QUESTION_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );
  deleteQuestion$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.DELETE_QUESTION),
      switchMap((props: { id: number }) => this.questionService.deleteQuestion(props.id).pipe(
        switchMap(() => of(QuestionActions.getQuestions(), SharedActions.openSuccessDialog({ messagesKey: MessagesKey.DELETE_QUESTION_SUCCESS }))),
        catchError((error) => of(SharedActions.openErrorDialog({ messagesKey: error }))),
      )),
    ),
  );

  getQuestionsDataTables$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.GET_QUESTIONS_DATA_TABLES),
      switchMap((data: { dataTablesParameters: any, callback: any }) => this.questionService.getQuestionsDataTables(data.dataTablesParameters).pipe(
        switchMap((response: DataTablesResponse) => of(QuestionActions.getQuestionsDataTablesSuccess(
          { dataTableResponse: response, callback: data.callback },
          )),
        )),
      ),
    ));

  getQuestionsDataTablesSuccess$ = createEffect(
    () => this.action$.pipe(
      ofType(QuestionActionsNames.GET_QUESTIONS_DATA_TABLES_SUCCESS),
      map((data: { dataTableResponse: DataTablesResponse, callback: any }) => {
        data.callback({
          recordsTotal: data.dataTableResponse.recordsTotal,
          recordsFiltered: data.dataTableResponse.recordsFiltered,
          data: [],
        });
      }),
    ), { dispatch: false },
  );
}
