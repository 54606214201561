import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProductApiEndpoints } from '../utils/constants';
import { Product } from '../model/product';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  PRODUCT_URL = environment.apiUrl + ProductApiEndpoints.BASE_PRODUCT_URL;

  constructor(private http: HttpClient) {
  }

  getProducts() {
    return this.http.get(this.PRODUCT_URL);
  }

  addProduct(product: Product) {
    return this.http.post(this.PRODUCT_URL, product);
  }

  getProduct(id: number) {
    return this.http.get<Product>(this.PRODUCT_URL + id);
  }

  updateProduct(product: Product) {
    return this.http.put(this.PRODUCT_URL + product.id, product);
  }

  deleteProduct(id: number) {
    return this.http.delete(this.PRODUCT_URL + id);
  }

  getProductsDataTables(dataTablesParametars: any) {
    return this.http.post(this.PRODUCT_URL + ProductApiEndpoints.PRODUCT_SEARCH, dataTablesParametars);
  }
}
