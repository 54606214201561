import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import { FieldNames, MessagesKey } from '../../../common/utils/constants';
import { MatDialog, MatInput, MatSelect } from '@angular/material';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { selectProductsDataTables, selectProductTypes } from '../../../store/product/selectors/product.selectors';
import * as ProductActions from '../../../store/product/actions/product.actions';
import { AddProductComponent } from '../add/add.component';
import { Observable } from 'rxjs';
import { Product } from '../../../common/model/product';

@Component({
  selector: 'app-list-product',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ProductListComponent implements OnInit {
  dtOptions: any = {};
  @ViewChild(DataTableDirective, { static: false })
  dataTableElement: DataTableDirective;
  @ViewChild(MatInput, { static: false }) search: MatInput;
  @ViewChild(MatSelect, { static: false }) type: MatSelect;

  products: Observable<Product[]>;
  productTypes: Observable<Map<string, string>>;

  constructor(private store$: Store<AppState>, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.products = this.store$.pipe(select(selectProductsDataTables));
    this.productTypes = this.store$.pipe(select(selectProductTypes));
    this.store$.dispatch(ProductActions.getProductTypes());
    this.initDataTable();
  }

  initDataTable() {
    this.dtOptions = {
      serverSide: true,
      processing: false,
      dom: 'lpt',
      language: {
        lengthMenu: '_MENU_ ',
        paginate: {
          previous: '&lt;',
          next: '&gt;',
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.columns[0].search['value'] = this.search.value;
        dataTablesParameters.columns[1].search['value'] = this.type.value;
        this.store$.dispatch(
          ProductActions.getProductsDataTables({
            dataTablesParameters,
            callback,
          }),
        );
      },
      columnDefs: [
        {
          searchable: false,
          targets: [0, 1, 2, 3, 4, 5, 6],
        },
      ],
      columns: [
        { data: FieldNames.PRODUCT_ID, orderable: true },
        { data: FieldNames.PRODUCT_NAME, orderable: false },
        { data: FieldNames.PRODUCT_FOUNDATION_YEAR, orderable: false },
        { data: FieldNames.PRODUCT_MANUFACTURER, orderable: false },
        { data: FieldNames.PRODUCT_TYPE, orderable: false },
        { data: FieldNames.PRODUCT_ID, orderable: false },
        { data: FieldNames.PRODUCT_MUNICIPALITY, orderable: false },
      ],
    };
  }

  addProduct() {
    this.openDialog(false, MessagesKey.ADD_PRODUCT_TITLE);
  }

  previewProduct(id) {
    this.openDialog(true, MessagesKey.PREVIEW_PRODUCT_TITLE, id);
  }

  editProduct(id) {
    this.openDialog(false, MessagesKey.UPDATE_PRODUCT_TITLE, id);
  }

  deleteProduct(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        titleKey: MessagesKey.DELETE_PRODUCT_TITLE,
        messagesKey: MessagesKey.DELETE_PRODUCT_MESSAGES,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.store$.dispatch(ProductActions.deleteProduct({ id: id }));
      }
    });
  }

  openDialog(disable: boolean, title: string, id?) {
    const dialogRef = this.dialog.open(AddProductComponent, {
      disableClose: true,
      data: { id, disable, title },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.draw();
    });
  }

  draw() {
    if (!!this.dataTableElement) {
      this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  searchProducts() {
    this.draw();
  }
}
