import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { routerReducer } from '@ngrx/router-store';
import { userReducers } from '../../user/reducers/user.reducers';
import { guiReducers } from '../../gui/reducers/gui.reducers';
import { companyReducers } from '../../company/reducers/company.reducers';
import { productReducers } from '../../product/reducers/product.reducers';
import { questionReducers } from '../../question/reducers/question.reducers';
import { templateReducers } from '../../template/reducers/template.reducers';
import { reportReducers } from '../../report/reducers/report.reduccers';

export const appReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  user: userReducers,
  gui: guiReducers,
  company: companyReducers,
  product: productReducers,
  question: questionReducers,
  template: templateReducers,
  report: reportReducers,
};
