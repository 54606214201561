import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APPLICANT_KEY, CommonLabels, FieldNames, MANUFACTURER_KEY } from '../../../common/utils/constants';
import { Company } from '../../../common/model/company';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app/state/app.state';
import * as CompanyActions from '../../../store/company/actions/company.actions';
import { Subscription } from 'rxjs';
import { selectCompany, selectCompanyTypes, selectIsModalOpen } from '../../../store/company/selectors/company.selectors';

@Component({
  selector: 'app-add-company',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  form: FormGroup;
  company: Company = {} as Company;
  modalToggleSubscription: Subscription;
  companySubscription: Subscription;
  companyTypes: Map<string, string>;
  companyTypesSubscription: Subscription;


  constructor(
    private dialogRef: MatDialogRef<AddCompanyComponent>,
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; disable: boolean; title: string, onlyApplicant: boolean, onlyManufacturer: boolean },
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.companyTypesSubscription = this.store$.pipe(select(selectCompanyTypes)).subscribe((data) => {
      if (this.data.onlyApplicant) {
        delete data[MANUFACTURER_KEY];
      }
      if (this.data.onlyManufacturer) {
        delete data[APPLICANT_KEY];
      }
      this.companyTypes = data;
    });
    this.store$.dispatch(CompanyActions.toggleModal({ isOpen: true }));
    this.store$.dispatch(CompanyActions.getCompanyTypes());

    this.modalToggleSubscription = this.store$
      .pipe(select(selectIsModalOpen))
      .subscribe((data) => {
        if (!data) {
          this.dialogRef.close();
        }
      });
    if (this.data.id) {
      this.store$.dispatch(CompanyActions.getCompany({ id: this.data.id }));
      this.companySubscription = this.store$
        .pipe(select(selectCompany))
        .subscribe((company) => {
          if (company) {
            this.company = company;
            this.form.patchValue(this.company);
          }
        });
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.COMPANY_ID]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
      ],
      [FieldNames.COMPANY_NAME]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.COMPANY_ADDRESS]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.COMPANY_MOBILE_PHONE]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.COMPANY_PIB]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.COMPANY_TYPE]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
        Validators.required,
      ],
      [FieldNames.COMPANY_ADDITIONAL_INFO]: [
        { value: CommonLabels.EMPTY, disabled: this.data.disable },
      ],
    });
  }

  save() {
    this.company = this.form.value;
    if (this.company.id) {
      this.store$.dispatch(
        CompanyActions.updateCompany({ company: this.company }),
      );
    } else {
      this.store$.dispatch(
        CompanyActions.addCompany({ company: this.company }),
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    this.modalToggleSubscription.unsubscribe();
    this.companyTypesSubscription.unsubscribe();
  }
}
