import { AppState } from '../../app/state/app.state';

export const selectTypes = (state: AppState) => state.report.types;
export const selectDiscordance = (state: AppState) => state.report.discordanceList;
export const selectReportsDataTables = (state: AppState) => state.report.reportsDataTables.data;
export const selectExcelReport = (state: AppState) => state.report.report;
export const selectExpReport = (state: AppState) => state.report.exp;
export const selectSelectedReport = (state: AppState) => state.report.selectedReport;
export const selectAnswers = (state: AppState) => state.report.answerList;
export const selectTypesWithGroup = (state: AppState) => state.report.typesWithGroup;
export const selectIsModalOpen = (state: AppState) => state.report.isModalOpen;
export const selectLoading = (state: AppState) => state.report.loading;
