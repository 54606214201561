import { createReducer, on } from '@ngrx/store';
import {
  addProductSuccess,
  getProductsDataTablesSuccess,
  getProductsSuccess,
  getProductSuccess,
  getProductTypesSuccess,
  ProductActions,
  toggleModal,
} from '../actions/product.actions';
import { INIT_PRODUCT_STATE, ProductState } from '../state/product.state';
import { Product } from '../../../common/model/product';


const reducers = createReducer(
  INIT_PRODUCT_STATE,
  on(getProductsSuccess, (state, { products }) => ({
    ...state,
    products,
    productsDataTables: {
      ...state.productsDataTables,
      data: products,
    },
  })),
  on(toggleModal, (state, { isOpen }) => ({
    ...state,
    isModalOpen: isOpen,
  })),
  on(getProductSuccess, (state, { product }) => ({
    ...state,
    selectedProduct: product,
  })),
  on(getProductsDataTablesSuccess, (state, { dataTableResponse }) => ({
    ...state,
    productsDataTables: dataTableResponse,
  })),
  on(getProductTypesSuccess, (state, { types }) => ({
    ...state,
    productTypes: types,
  })),
  on(addProductSuccess, (state, { product }) => ({
    ...state,
    lastAddedProduct: product,
    products: getNewProductList(state.products, product),
  })),
);

function getNewProductList(products: Product[], product: Product) {
  const productsCopy = products.slice();
  productsCopy.push(product);
  return productsCopy;
}

export function productReducers(state: ProductState | undefined, action: ProductActions) {
  return reducers(state, action);
}
