import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Company, CompanyType } from '../../../common/model/company';
import { Group, GroupQuestion, HeaderItem, Template } from '../../../common/model/template';
import * as TemplateAction from '../../../store/template/actions/template.actions';
import * as SharedAction from '../../../store/shared/actions/shared.actions';

import { AppState } from '../../../store/app/state/app.state';
import { select, Store } from '@ngrx/store';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Product } from '../../../common/model/product';
import * as ReportAction from '../../../store/report/actions/report.actions';

import {
  ANSWER_TYPE_JSON,
  ANSWER_TYPE_SUFFIX,
  CommonLabels,
  COMPANY,
  ESCALATOR_BEF0RE_USE_CONTROL,
  EXPIRY_TYPE_EXACT_DATE,
  EXTRAORDINARY_ELEVATOR_CONTROL,
  FieldNames,
  FINAL_ELECTRIC_ELEVATOR_CONTROL,
  FINAL_HYDRAULIC_ELEVATOR_CONTROL,
  FINAL_MLR_ELEVATOR_CONTROL,
  GROUP_TYPE_GROUP_WITH_SUBLIST,
  GROUP_TYPE_TABLE,
  MessagesKey,
  REGULAR_ELEVATOR_CONTROL,
  SINGLE_ELECTRIC_ELEVATOR_VERIFICATION,
  SINGLE_HYDRAULIC_ELEVATOR_VERIFICATION,
  SINGLE_MLR_ELEVATOR_VERIFICATION,
  SPEED_LIMITER_BEFORE_USE_CONTROL,
  TRACKWAY_BEF0RE_USE_CONTROL,
} from '../../../common/utils/constants';
import { Answer, Report, ReportDiscordance } from '../../../common/model/report';
import { ReportService } from '../../../common/api/report.service';
import {
  selectAnswers,
  selectDiscordance,
  selectLoading,
  selectSelectedReport,
  selectTypes,
  selectTypesWithGroup,
} from '../../../store/report/selectors/report.selectors';
import { selectTemplate, selectTemplates } from '../../../store/template/selectors/template.selectors';
import { selectFilteredCompanies, selectLastAddedCompany } from '../../../store/company/selectors/company.selectors';
import { selectLastAddedProduct, selectProducts } from '../../../store/product/selectors/product.selectors';
import * as CompanyAction from '../../../store/company/actions/company.actions';
import * as ProductAction from '../../../store/product/actions/product.actions';
import { Discordance } from '../../../common/model/discordance';
import { ActivatedRoute } from '@angular/router';
import { DropdownAnswer } from '../../../common/model/dropdown_answer';
import { selectUser } from '../../../store/user/selectors/user.selectors';
import { AddProductComponent } from '../../product/add/add.component';
import { MatDialog } from '@angular/material';
import { AddCompanyComponent } from '../../company/add/add.component';
import { CompanyService } from '../../../common/api/company.service';
import { AddDiscordanceComponent } from '../../discordance/add/add.component';

enum SelectedAction {
  ADD_INVESTOR = 'ADD_INVESTOR',
  ADD_APPLICANT = 'ADD_APPLICANT'
}

@Component({
  selector: 'app-add-report',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddReportComponent implements OnInit, OnDestroy {
  template: Template;
  report: Report = {} as Report;
  form: FormGroup;
  groupArray: FormArray;
  discordanceArray: FormArray;
  onDestroy = new Subject<void>();
  groupWithSubgroup = GROUP_TYPE_GROUP_WITH_SUBLIST;
  table = GROUP_TYPE_TABLE;
  answerTypeSuffix = ANSWER_TYPE_SUFFIX;

  companiesInvestorSubscription: Subscription;
  filteredInvestorCompanies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>();
  public companyInvestorFilterCtrl: FormControl = new FormControl();

  companiesApplicantSubscription: Subscription;
  filteredApplicantCompanies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>();
  public companyApplicantFilterCtrl: FormControl = new FormControl();

  templatesSubscription: Subscription;
  selectedTemplatesSubscription: Subscription;
  templatesForCopySubscription: Subscription;
  templates: Template[];
  filteredTemplates: ReplaySubject<Template[]> = new ReplaySubject<Template[]>();
  public templateFilterCtrl: FormControl = new FormControl();
  types: Map<string, Array<any>>;
  typesWithGroup: Map<string, string> = new Map<string, string>();
  typesSubscription: Subscription;

  productsSubscription: Subscription;
  newProductSubscription: Subscription;
  newCompanySubscription: Subscription;
  products: Product[];
  filteredProducts: ReplaySubject<Product[]> = new ReplaySubject<Product[]>();
  public productFilterCtrl: FormControl = new FormControl();


  // filteredDiscordance: Observable<Discordance[]>;

  discordanceSubscription: Subscription;
  discordances: Discordance[];
  filteredDiscordance: ReplaySubject<Discordance[]> = new ReplaySubject<Discordance[]>();
  public discordanceFilterCtrl: FormControl = new FormControl();
  reportSubscription: Subscription;
  hideActions: boolean = false;

  isCopyAction: boolean = false;
  isCertificate: boolean = false;
  showExpiryType = false;
  showExpiryDate = false;


  userSubscription: Subscription;

  filteredAnswers: Observable<DropdownAnswer[]>;
  selectedAction: string;
  addReportActionCall: boolean = false;
  loading: boolean = true;
  loadingSubscription: Subscription;

  constructor(private store$: Store<AppState>,
              private formBuilder: FormBuilder,
              private reportService: ReportService,
              private activeRoute: ActivatedRoute,
              private dialog: MatDialog,
              private companyService: CompanyService,
              private el: ElementRef) {
  }

  initSearch(filterControl: FormControl,
             data,
             filteredData,
             selector,
             filterMethod) {
    filterControl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        filterMethod(value, data, filteredData);
      });

    return this.store$.pipe(select(selector)).subscribe((response) => {
      data = response;
      filteredData.next(data);
    });
  }

  setCompanies(applicantId: number, investorId: number) {
    this.companyService.getCompany(applicantId).subscribe(value => {
      this.filteredApplicantCompanies.next([value]);
    });
    this.companyService.getCompany(investorId).subscribe(value => {
      this.filteredInvestorCompanies.next([value]);
    });
  }

  initApplicantSearch() {
    this.companyApplicantFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        this.store$.dispatch(CompanyAction.searchCompanies({ search: value, companyType: CompanyType.APPLICANT }));
      });

    this.companiesApplicantSubscription = this.store$.pipe(select(selectFilteredCompanies)).subscribe((response) => {
      if (this.companyApplicantFilterCtrl.value !== null && this.companyApplicantFilterCtrl.value !== '') {
        this.filteredApplicantCompanies.next(response.slice());
      }
    });
  }

  initInvestorSearch() {
    this.companyInvestorFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        this.store$.dispatch(CompanyAction.searchCompanies({ search: value, companyType: CompanyType.BOTH }));
      });

    this.companiesInvestorSubscription = this.store$.pipe(select(selectFilteredCompanies)).subscribe((response) => {
      if (this.companyInvestorFilterCtrl.value !== null && this.companyInvestorFilterCtrl.value !== '') {
        this.filteredInvestorCompanies.next(response.slice());
      }
    });
  }

  ngOnInit() {
    this.initForm();
    // this.store$.dispatch(CompanyAction.getCompanies({ companyType: CompanyType.APPLICANT }));
    this.store$.dispatch(TemplateAction.getTemplates({ withDeprecated: false }));
    this.store$.dispatch(ProductAction.getProducts());
    this.store$.dispatch(ReportAction.getTypes());
    this.store$.dispatch(ReportAction.getTypesWithGroup());
    this.store$.dispatch(ReportAction.getDiscordance({ search: '' }));

    this.filteredAnswers = this.store$.pipe(select(selectAnswers));
    this.loadingSubscription = this.store$.pipe(select(selectLoading)).subscribe(value => this.loading = value);

    this.typesSubscription = this.store$.pipe(select(selectTypesWithGroup)).subscribe((result) => {
      this.typesWithGroup = result;
      console.log(this.typesWithGroup);
    });

    this.selectedTemplatesSubscription = this.store$
      .select(selectTemplate)
      .subscribe((data) => {
        this.template = data;
        if (data) {
          this.populateForm(this.template, null);
          if (this.isCopyAction) {
            // this.filterTemplates(this.template);
            this.report.template.id = this.template.id;
            this.store$.dispatch(
              SharedAction.openSuccessDialog({
                messagesKey: MessagesKey.CHANGE_TEMPLATE_SUCCESS,
              }),
            );
          }
        }
      });

    this.store$.select(selectTypes).subscribe((data) => {
      this.types = Object.keys(data).reduce((destination, key) => {
        destination.set(key.toUpperCase(), data[key]);
        return destination;
      }, new Map());
    });


    this.initApplicantSearch();
    this.initInvestorSearch();

    this.templatesSubscription = this.initSearch(
      this.templateFilterCtrl,
      this.templates,
      this.filteredTemplates,
      selectTemplates,
      this.filterData,
    );

    this.productsSubscription = this.initSearch(
      this.productFilterCtrl,
      this.products,
      this.filteredProducts,
      selectProducts,
      this.filterData,
    );

    this.templatesForCopySubscription = this.store$
      .pipe(select(selectTemplates))
      .subscribe((data) => {
        if (data) {
          this.templates = data;
        }
      });

    this.discordanceFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy), debounceTime(300))
      .subscribe((value) => {
        this.store$.dispatch(ReportAction.getDiscordance({ search: value }));
      });

    this.discordanceSubscription = this.store$
      .pipe(select(selectDiscordance))
      .subscribe((response) => {
        this.discordances = response;
        this.filteredDiscordance.next(this.discordances);
      });
    const id = +this.activeRoute.snapshot.params['id'];
    if (id) {
      this.hideActions = true;
      const isCopy = this.activeRoute.snapshot.queryParams['isCopy'];
      this.store$.dispatch(ReportAction.getReport({ id: id }));
      this.reportSubscription = this.store$
        .pipe(select(selectSelectedReport))
        .subscribe((data) => {
          if (data) {
            this.report = data;
            if (isCopy) {
              this.filterTemplates(data.template);
            }
            this.setCompanies(this.report.applicantId, this.report.investorId);
            this.populateFormWithReport(data, isCopy);
            this.setExpiration(this.report.expiryType, this.report.template.reportType);
            this.setValidators(this.report.template);
          }
        });
    }
    this.newProductSubscription = this.store$.pipe(select(selectLastAddedProduct)).subscribe((data) => {
      if (data && this.addReportActionCall) {
        this.form.get(FieldNames.REPORT_PRODUCT).setValue(data.id);
      }
    });
    this.newCompanySubscription = this.store$.pipe(select(selectLastAddedCompany)).subscribe((data) => {
      if (data && this.selectedAction) {
        switch (this.selectedAction) {
          case SelectedAction.ADD_APPLICANT:
            this.filteredApplicantCompanies.next([data]);
            this.form.get(FieldNames.REPORT_APPLICANT).setValue(data.id);
            break;
          case SelectedAction.ADD_INVESTOR:
            this.filteredInvestorCompanies.next([data]);
            this.form.get(FieldNames.REPORT_INVESTOR).setValue(data.id);
            break;
          default:
            break;
        }
        this.selectedAction = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.store$.dispatch(TemplateAction.removeTemplate());
    this.store$.dispatch(ReportAction.removeReport());
    this.companiesApplicantSubscription.unsubscribe();
    this.companiesInvestorSubscription.unsubscribe();
    this.productsSubscription.unsubscribe();
    this.templatesSubscription.unsubscribe();
    this.discordanceSubscription.unsubscribe();
    this.templatesForCopySubscription.unsubscribe();
    this.selectedTemplatesSubscription.unsubscribe();
    this.typesSubscription.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    this.newProductSubscription.unsubscribe();
    this.newCompanySubscription.unsubscribe();
  }

  initForm() {
    this.form = this.formBuilder.group({
      [FieldNames.REPORT_ID]: [''],
      [FieldNames.REPORT_PRODUCT]: ['', Validators.required],
      [FieldNames.REPORT_EXPIRY_TYPE]: [''],
      [FieldNames.REPORT_EXPIRY_DATE]: [''],
      [FieldNames.REPORT_TEMPLATE]: ['', Validators.required],
      [FieldNames.REPORT_APPLICANT]: ['', Validators.required],
      // [FieldNames.REPORT_INVESTOR]: ['', Validators.required],
      // [FieldNames.REPORT_NUMBER]: ['', Validators.required],
      [FieldNames.REPORT_WORK_ORDER_NUMBER]: ['', Validators.required],
      [FieldNames.REPORT_CONTROL_DATE]: ['', Validators.required],
      [FieldNames.REPORT_ISSUE_DATE]: ['', Validators.required],
      [FieldNames.TEMPLATE_GROUPS]: this.formBuilder.array([]),
      [FieldNames.REPORT_DISCORDANCES]: this.formBuilder.array([]),
    });
    this.groupArray = this.form.get(FieldNames.TEMPLATE_GROUPS) as FormArray;
    this.discordanceArray = this.form.get(
      FieldNames.REPORT_DISCORDANCES,
    ) as FormArray;
  }

  generateReport() {
    this.store$.dispatch(
      TemplateAction.getTemplate({
        id: this.form.get(FieldNames.REPORT_TEMPLATE).value,
      }),
    );
  }

  filterData(search: string, data, filteredData) {
    if (!data) {
      return;
    }
    if (!search) {
      filteredData.next(data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filteredData.next(
      data.filter((item) => item.name.toLowerCase().indexOf(search) > -1),
    );
  }

  populateForm(template: Template, report: Report) {
    this.groupArray.clear();
    template.groups.map((group) => {
      const isForCopy: boolean = this.isCopyAction && !report;
      this.groupArray.push(
        this.formBuilder.group({
          [FieldNames.TEMPLATE_GROUP_NAME]: [group.name],
          [FieldNames.TEMPLATE_GROUP_DESCRIPTION]: [group.description],
          [FieldNames.TEMPLATE_GROUP_TYPE]: [group.groupType],
          [FieldNames.TEMPLATE_GROUP_QUESTIONS]: group.questions
            ? this.populateQuestionsArray(
              group.questions,
              group.headerItems,
              report ? report.answers : null,
              isForCopy ? group.name : null,
              null,
            )
            : this.formBuilder.array([]),
          [FieldNames.TEMPLATE_GROUP_SUBGROUPS]: group.subgroups
            ? this.populateSubgroupArray(
              group.subgroups,
              report ? report.answers : null,
              isForCopy ? group.name : null,
            )
            : this.formBuilder.array([]),
        }),
      );
    });
  }

  populateSubgroupArray(groups: Group[],
                        answers: Answer[],
                        groupNameForCopy: string) {
    const subgroupArray = this.formBuilder.array([]);
    groups.map((group) => {
      subgroupArray.push(
        this.formBuilder.group({
          [FieldNames.TEMPLATE_GROUP_NAME]: [group.name],
          [FieldNames.TEMPLATE_GROUP_DESCRIPTION]: [group.description],
          [FieldNames.TEMPLATE_GROUP_QUESTIONS]: group.questions
            ? this.populateQuestionsArray(
              group.questions,
              group.headerItems,
              answers,
              !!groupNameForCopy ? groupNameForCopy : null,
              !!groupNameForCopy ? group.name : '',
            )
            : this.formBuilder.array([]),
        }),
      );
    });
    return subgroupArray;
  }

  populateQuestionsArray(questions: GroupQuestion[],
                         headerItems: HeaderItem[],
                         answers: Answer[],
                         groupNameForCopy: string,
                         subGroupNameForCopy: string) {
    const questionArray = this.formBuilder.array([]);
    const groupForCopy = !!groupNameForCopy
      ? this.report.template.groups.find(
        (item) => item.name === groupNameForCopy,
      )
      : null;
    const subGroupForCopy =
      !!subGroupNameForCopy && !!groupForCopy
        ? groupForCopy.subgroups.find(
        (item) => item.name === subGroupNameForCopy,
        )
        : null;
    questions.map((question) => {
      const answer = answers
        ? answers.find(
          (a) =>
            a.groupId === question.groupId &&
            a.questionId === question.questionId,
        )
        : null;
      const answerFromReport =
        !!subGroupNameForCopy && !!subGroupForCopy
          ? this.report.answers.find(
          (a) =>
            a.questionId === question.questionId &&
            a.groupId === subGroupForCopy.id,
          )
          : !subGroupNameForCopy && !!groupNameForCopy && !!groupForCopy
          ? this.report.answers.find(
            (a) =>
              a.questionId === question.questionId &&
              a.groupId === groupForCopy.id,
          )
          : null;

      const questionFormGroup = this.formBuilder.group({
        questionId: [question.questionId],
        groupId: [question.groupId],
        value: [question.value],
        answerId: [answer ? answer.id : CommonLabels.EMPTY],
        answerType: [headerItems ? ANSWER_TYPE_JSON : question.answerType],
        answer: headerItems
          ? this.populateHeaderItemsArray(
            headerItems,
            answer,
            answerFromReport,
          )
          : [
            answer
              ? this.getValue(answer.answer)
              : !!answerFromReport
              ? question.answerType === COMPANY
                ? parseInt(answerFromReport.answer)
                : answerFromReport.answer
              : CommonLabels.EMPTY,
            Validators.required,
          ],
      });

      if (this.typesWithGroup[question.answerType] === 'DROPDOWN') {
        questionFormGroup.get('answer').valueChanges.pipe(
          debounceTime(300),
        ).subscribe((search: string) => {
          this.store$.dispatch(ReportAction.getAnswers({ search, answerType: question.answerType }));
        });
      }
      questionArray.push(
        questionFormGroup,
      );
    });
    return questionArray;
  }

  saveReport() {
    const reportForm = this.form.getRawValue();
    this.report.id = reportForm.id;
    this.report.templateId = reportForm.template;
    this.report.productId = reportForm.product;
    this.report.applicantId = reportForm.applicant;
    this.report.workOrderNumber = reportForm.workOrderNumber;
    this.report.issueDate = reportForm.issueDate;
    this.report.controlDate = reportForm.controlDate;
    this.report.investorId = reportForm.investor;
    this.report.expiryType = reportForm.expiryType;
    this.report.expiryDate = reportForm.expiryDate;

    if (this.report.expiryType === '') {
      this.report.expiryType = 'PERMANENTLY';
    }

    this.report.answers = [];
    this.report.discordances = this.discordanceArray.value;

    reportForm.groups.map((group, groupIndex) => {
      group.questions.map((question) => {
        this.report.answers.push({
          id: question.answerId,
          questionId: question.questionId,
          groupId: question.groupId,
          answer: question.answer,
        });
      });

      group.subgroups.map((subgroup) => {
        subgroup.questions.map((question) => {
          this.report.answers.push({
            id: question.answerId,
            questionId: question.questionId,
            groupId: question.groupId,
            answer:
              question.answerType === ANSWER_TYPE_JSON
                ? this.populateAnswerAsJson(question.answer)
                : question.answer,
          });
        });
      });
    });

    if (this.report.id) {
      this.store$.dispatch(ReportAction.updateReport({ report: this.report }));
    } else {
      this.userSubscription = this.store$.pipe(select(selectUser)).subscribe((user) => {
        this.report.createdBy = user.username;
        this.store$.dispatch(ReportAction.addReport({ report: this.report }));
      });
    }
  }

  deleteDiscordance(discordanceIndex) {
    this.discordanceArray.removeAt(discordanceIndex);
    this.discordanceArray.controls.forEach(control => control.get(FieldNames.DISCORDANCE_ID).updateValueAndValidity());
  }

  addDiscordance() {
    const formGroup = this.createDiscordanceForm();
    // formGroup.get(FieldNames.DISCORDANCE_ID).valueChanges.pipe(
    //   filter(data => data.trim().length > 0),
    //   debounceTime(500),
    // ).subscribe((search: string) => {
    //   this.store$.dispatch(ReportAction.getDiscordance({ search }));
    // });
    this.discordanceArray.push(formGroup);
  }

  createDiscordanceForm() {
    return this.formBuilder.group({
      [FieldNames.DISCORDANCE_ID]: ['', [Validators.required, this.uniqueDiscordances.bind(this)]],
      [FieldNames.DISCORDANCE_CORRECTIVE_MEASURE]: [''],
      [FieldNames.DISCORDANCE_SEPARATE]: [false],
    });
  }

  uniqueDiscordances(control: AbstractControl): ValidationErrors | null {
    if (this.discordanceArray && control.value) {
      if (this.discordanceArray.controls.some(ctrl => {
        return ctrl.get(FieldNames.DISCORDANCE_ID) !== control && ctrl.get(FieldNames.DISCORDANCE_ID).value === control.value;
      })) {
        control.setErrors({ notUnique: true });
        return { notUnique: true };
      }
    }
    return null;
  }

  populateHeaderItemsArray(headerItems: HeaderItem[],
                           answer: Answer,
                           answerForCopy: Answer): FormArray {
    const answerValue = JSON.parse(answer ? answer.answer : null);
    const answerForCopyValue = JSON.parse(
      answerForCopy ? answerForCopy.answer : null,
    );
    const headerItemArray = this.formBuilder.array([]);
    headerItems.map((headerItem) => {
      headerItemArray.push(
        this.formBuilder.group({
          [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_VALUE]: [headerItem.value],
          [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_ANSWER_TYPE]: [
            headerItem.type,
          ],
          [FieldNames.TEMPLATE_GROUP_HEADER_ITEM_ANSWER]: [
            answer
              ? answerValue[headerItem.value]
              : !!answerForCopyValue
              ? answerForCopyValue[headerItem.value]
                ? answerForCopyValue[headerItem.value]
                : CommonLabels.EMPTY
              : CommonLabels.EMPTY,
            headerItem.type == 'CLOSED' ? Validators.required : {},
          ],
        }),
      );
    });
    return headerItemArray;
  }

  private populateAnswerAsJson(answerArray): string {
    let answer = {};
    answerArray.map((item) => {
      answer = Object.assign({}, answer, { [item.value]: item.answer });
      console.log(answer);
    });
    return JSON.stringify(answer);
  }

  private populateFormWithReport(data: Report, isCopy: boolean) {
    this.form.get(FieldNames.REPORT_ID).setValue(isCopy ? '' : data.id);
    this.form.get(FieldNames.REPORT_PRODUCT).setValue(data.productId);
    this.form.get(FieldNames.REPORT_TEMPLATE).setValue(data.templateId);
    !isCopy ? this.form.get(FieldNames.REPORT_TEMPLATE).disable() : null;
    // this.form.get(FieldNames.REPORT_INVESTOR).setValue(data.investorId);
    this.form.get(FieldNames.REPORT_APPLICANT).setValue(data.applicantId);
    this.form.get(FieldNames.REPORT_EXPIRY_TYPE).setValue(data.expiryType);
    this.form.get(FieldNames.REPORT_EXPIRY_DATE).setValue(data.expiryDate);
    debugger;
    this.form
      .get(FieldNames.REPORT_WORK_ORDER_NUMBER)
      .setValue(data.workOrderNumber);
    this.form.get(FieldNames.REPORT_CONTROL_DATE).setValue(data.controlDate);
    this.form.get(FieldNames.REPORT_ISSUE_DATE).setValue(data.issueDate);
    this.populateForm(data.template, data);
    this.populateDiscordance(data.discordances);
  }

  private populateDiscordance(discordances: ReportDiscordance[]) {
    discordances.map((discordance) => {
      this.discordanceArray.push(
        this.formBuilder.group({
          [FieldNames.DISCORDANCE_ID]: [
            discordance.discordanceId,
            [Validators.required, this.uniqueDiscordances.bind(this)],
          ],
          [FieldNames.DISCORDANCE_CORRECTIVE_MEASURE]: [
            discordance.correctiveMeasure,
          ],
          [FieldNames.DISCORDANCE_SEPARATE]: [
            discordance.separate,
          ],
        }),
      );
    });
  }

  private getValue(answer: string) {
    const isNumber = /^\d+$/.test(answer);
    return isNumber ? +answer : answer;
  }

  private filterTemplates(template: Template) {
    this.isCopyAction = true;
  }

  onFocus(type) {
    this.store$.dispatch(ReportAction.getAnswers({ search: '', answerType: type }));
  }

  onKeyup(type, value) {
    this.store$.dispatch(ReportAction.getAnswers({ search: value, answerType: type }));
  }

  onTemplateChange(event) {
    const template = this.templates.find(t => t.id === event.value);
    this.setExpiration(this.form.get(FieldNames.REPORT_EXPIRY_TYPE).value, template.reportType);
    this.setValidators(template);
  }

  onExpiryTypeChange(event) {
    this.showExpiryDate = event.value === EXPIRY_TYPE_EXACT_DATE;
    this.setValidator(FieldNames.REPORT_EXPIRY_DATE, this.showExpiryDate);
  }

  setExpiration(expiryType: string, reportType: string) {
    this.showExpiryType = this.hasExpiration(reportType);
    this.showExpiryDate = this.showExpiryType && expiryType === EXPIRY_TYPE_EXACT_DATE;
  }

  setValidators(template) {
    this.isCertificate = this.isTemplateCertificate(template.reportType);
    // this.setValidator(FieldNames.REPORT_INVESTOR, this.isCertificate);
    this.setValidator(FieldNames.REPORT_WORK_ORDER_NUMBER, this.isCertificate);
    this.setValidator(FieldNames.REPORT_EXPIRY_TYPE, this.showExpiryType);
    this.setValidator(FieldNames.REPORT_EXPIRY_DATE, this.showExpiryDate);
  }

  setValidator(fieldName, isRequired) {
    if (isRequired) {
      this.form.get(fieldName).setValidators([Validators.required]);
    } else {
      this.form.get(fieldName).clearValidators();
    }
    this.form.get(fieldName).updateValueAndValidity();
  }

  isTemplateCertificate(reportType: string): boolean {
    switch (reportType) {
      case FINAL_ELECTRIC_ELEVATOR_CONTROL:
      case SINGLE_ELECTRIC_ELEVATOR_VERIFICATION:
      case FINAL_HYDRAULIC_ELEVATOR_CONTROL:
      case SINGLE_HYDRAULIC_ELEVATOR_VERIFICATION:
      case FINAL_MLR_ELEVATOR_CONTROL:
      case SINGLE_MLR_ELEVATOR_VERIFICATION:
      case SPEED_LIMITER_BEFORE_USE_CONTROL:
      case ESCALATOR_BEF0RE_USE_CONTROL:
      case TRACKWAY_BEF0RE_USE_CONTROL:
        return true;
      default:
        return false;
    }
  }

  hasExpiration(reportType: string): boolean {
    switch (reportType) {
      case REGULAR_ELEVATOR_CONTROL:
      case EXTRAORDINARY_ELEVATOR_CONTROL:
      case FINAL_ELECTRIC_ELEVATOR_CONTROL:
      case FINAL_HYDRAULIC_ELEVATOR_CONTROL:
      case SINGLE_ELECTRIC_ELEVATOR_VERIFICATION:
      case SINGLE_HYDRAULIC_ELEVATOR_VERIFICATION:
      case ESCALATOR_BEF0RE_USE_CONTROL:
      case TRACKWAY_BEF0RE_USE_CONTROL:
        return false;
      default:
        return true;
    }
  }

  openAddProductModal() {
    this.addReportActionCall = true;
    const dialogRef = this.dialog.open(AddProductComponent, {
      disableClose: true,
      data: { id: null, disable: false, title: MessagesKey.ADD_PRODUCT_TITLE },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.store$.dispatch(CompanyAction.getCompanies({ companyType: CompanyType.APPLICANT }));
    });
  }

  openAddDiscordanceModal() {

  }

  openAddCompanyModal(action: string, onlyApplicant: boolean) {
    this.selectedAction = action;
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      disableClose: true,
      data: { id: null, disable: false, title: MessagesKey.ADD_COMPANY_TITLE, onlyApplicant },
    });
  }

  checkEmptyFields() {
    const invalidControl = this.el.nativeElement.querySelector('input.ng-invalid,mat-select.ng-invalid');

    if (invalidControl) {
      invalidControl.focus();
    }
  }

  insertNewDiscordance() {
    const dialogRef = this.dialog.open(AddDiscordanceComponent, {
      width: '30%',
      height: '40%',
      data: { title: MessagesKey.ADD_DISCORDANCE_TITLE },
    });
  }
}
